import { memo } from 'react';
import { tv } from 'tailwind-variants';
import { EmoIcon as Icon } from '../EmoIcon';
import { BrandCardProps } from './BrandCard.types';

type BrandCardInfoProps = Pick<BrandCardProps, 'variant' | 'size' | 'englishName' | 'koreanName'>;
const brandCardInfo = tv(
  {
    slots: {
      base: 'flex h-full flex-col',
      engNameWrap: 'flex min-w-[0] items-center gap-2',
      engName: 'text-primary',
      korName: 'text-primary-hover',
    },
    variants: {
      size: {
        small: {
          engName: 'text-l-bold',
          korName: 'text-s',
        },
        medium: {
          base: 'gap-2',
          engName: 'text-title-xs-bold',
          korName: 'text-m',
        },
      },
    },
    compoundSlots: [
      // common
      { slots: ['engName', 'korName'], class: 'overflow-hidden text-ellipsis whitespace-nowrap' },
    ],
  },
  {
    // FIXME:(신다혜) twMerge가 false로 설정되어 있어서 class가 정상적으로 merge되지 않는 문제가 발생할 수 있습니다.
    // 추가 Config 를 통해 twMerge: true를 원복하여 정상 동작하도록 수정 조치가 필요합니다.
    twMerge: false,
  },
);

function BrandCardInfo({ variant, size, englishName, koreanName }: BrandCardInfoProps) {
  const style = brandCardInfo({ size });

  return (
    <div className={style.base()}>
      <div className={style.engNameWrap()}>
        <span className={style.engName()}>{englishName}</span>
        {variant === 'like' ? (
          <Icon
            className="shrink-0"
            type="chevronRight"
            size={size === 'small' ? 14 : 18}
            color="primary"
            weight="bold"
          />
        ) : null}
      </div>
      <div className={style.korName()}>{koreanName}</div>
    </div>
  );
}

export default memo(BrandCardInfo);
