import { isDefined } from '@29cm/utils-primitives';

export interface Sizes {
  sm: string;
  md?: string;
  lg?: string;
}

/**
 * @param {object} [sizes]
 * @param {string} [sizes.sm] - (max-width: 540px) 화면 크기에 대한 사이즈 문자열
 * @param {string} [sizes.md] - (min-width: 541px) 화면 크기에 대한 사이즈 문자열
 * @param {string} [sizes.lg] - (min-width: 1024px) 화면 크기에 대한 사이즈 문자열
 * @returns {string | undefined}
 */
export const makeSizesString = (sizes?: Sizes) => {
  if (sizes === undefined) {
    return undefined;
  }
  const { sm, md, lg } = sizes;

  return [
    lg !== undefined ? `(min-width: 1024px) ${lg}` : undefined,
    md !== undefined ? `(min-width: 541px) ${md}` : undefined,
    sm,
  ]
    .filter(isDefined)
    .join(', ');
};
