import { CacheRegistry, CacheSubscriber, GlobalCacheRegistry } from '@29cm/contexts-common-contexts';

export const createCacheStore = <T>(key: string, globalCacheRegistry: GlobalCacheRegistry) => {
  const getCacheRegistry = (): CacheRegistry => {
    return globalCacheRegistry.get(key) ?? {};
  };

  const emit = (value: T) => {
    const { subscribers } = getCacheRegistry();

    subscribers?.forEach((subscriber) => subscriber(value));
  };

  const subscribe = (subscriber: CacheSubscriber<T>) => {
    const registry = getCacheRegistry();

    const subscribers = registry?.subscribers ?? new Set();

    globalCacheRegistry.set(key, {
      ...registry,
      subscribers: subscribers.add(subscriber as CacheSubscriber),
    });

    return () => unsubscribe(subscriber as CacheSubscriber<unknown>);
  };

  const unsubscribe = (subscriber: CacheSubscriber<unknown>) => {
    const { subscribers } = getCacheRegistry();

    subscribers?.delete(subscriber);
  };

  const set = (value: T, ttl: number) => {
    const registry = getCacheRegistry();

    globalCacheRegistry.set(key, {
      ...registry,
      cache: {
        expireAt: Date.now() + ttl,
        value,
      },
    });

    emit(value);
  };

  const remove = () => {
    const registry = getCacheRegistry();

    globalCacheRegistry.set(key, {
      ...registry,
      cache: undefined,
    });
  };

  const get = (): T | undefined => {
    if (isStale()) {
      remove();
      return;
    }

    const { cache } = getCacheRegistry();

    return cache?.value as T | undefined;
  };

  const isStale = (): boolean => {
    const { cache } = getCacheRegistry();

    const expireAt = cache?.expireAt ?? 0;

    return expireAt < Date.now();
  };

  return {
    set,
    get,
    isStale,
    subscribe,
    remove,
  };
};
