import { FetcherKey, apihubApiPath, apihubCacheApiPath, appEnv } from '@29cm/contexts-common-constants';
import { createFetcher } from '@29cm/contexts-http/services';
import { isServer } from '@29cm/utils-contexts';
import { urlJoin } from '@29cm/utils-url';
import * as v from 'valibot';
import { LoginResponse, SocialLoginResponse } from '../interfaces';

const apiPath = appEnv === 'development' ? apihubApiPath : apihubCacheApiPath;

export const fetchLogin = createFetcher<
  { id: string; password: string; accessToken?: string; socialType?: string },
  LoginResponse
>({
  key: FetcherKey.FetchLogin,
  method: 'POST',
  url: () => urlJoin(apiPath, '/user/login/'),
  requestOption: (params) => ({
    // FIXME: method를 전달하지 않아도 위에 정의한 method로 API 요청을 할 수 있도록 수정 필요
    method: 'POST',
    body: JSON.stringify({
      user_id: params.id,
      user_password: params.password,
      access_token: params.accessToken,
      social_type: params.socialType,
    }),
    headers: { 'Content-Type': 'application/json' },
  }),
  validator: (data) => v.safeParse(LoginResponse, data).issues?.map((i) => i.message),
});

export const fetchSocialLogin = createFetcher<
  { socialType: string; accessToken: string; redirectUri: string },
  SocialLoginResponse
>({
  key: FetcherKey.FetchSocialLogin,
  method: 'POST',
  url: () => urlJoin(apiPath, '/user/sns-login/'),
  requestOption: (params) => ({
    method: 'POST',
    body: JSON.stringify({
      access_token: params.accessToken,
      social_type: params.socialType,
      redirect_uri: params.redirectUri,
    }),
    headers: { 'Content-Type': 'application/json' },
  }),
  validator: (data) => v.safeParse(SocialLoginResponse, data).issues?.map((i) => i.message),
});

export const fetchLogout = createFetcher({
  key: FetcherKey.FetchLogout,
  method: 'GET',
  url: () => urlJoin(apihubApiPath, `/user/logout/`),
  validator: () => [],
});

/**
 * 주어진 href 또는 document.location.href 를 인코딩하여 리다이렉션 URL 파라미터를 생성합니다.
 * @param {string} [href] 리다이렉션 URI로 사용할 URL 문자열입니다. 제공되지 않으면 document.location.href 가 사용됩니다.
 * @returns {string | undefined} `?redirect_uri=${redirectUri}` 형식의 문자열을 반환합니다. SSR 에서는 undefined 를 반환 합니다.
 */
export const getRedirectUriParam = (href?: string): string | undefined => {
  if (isServer()) {
    return;
  }
  const redirectUri = encodeURIComponent(href ?? document.location.href);
  return `?redirect_uri=${redirectUri}`;
};
