// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Func = (...args: any[]) => any;

export const createPromiseStore = (key: string, promiseMap = new Map<string, Promise<unknown>>()) => {
  const batch =
    <T extends Func>(func: T) =>
    (...args: Parameters<T>): ReturnType<T> => {
      if (promiseMap.has(key)) {
        return promiseMap.get(key)! as ReturnType<T>;
      }

      const promise = func(...args);

      promise.then(() => {
        promiseMap.delete(key);
      });

      promiseMap.set(key, promise);

      return promise as ReturnType<T>;
    };

  return {
    batch,
  };
};
