'use client';

import { PropsWithChildren, useMemo } from 'react';
import { createCookieStore } from '../../services';
import { CookieContext } from './CookieContext';

interface Props {
  initialCookie?: string;
}

export const CookieProvider = ({ children, initialCookie = '' }: PropsWithChildren<Props>) => {
  const store = useMemo(() => createCookieStore(), []);

  return <CookieContext.Provider value={{ store, initialCookie }}>{children}</CookieContext.Provider>;
};
