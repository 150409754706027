'use client';

import { IconButton } from '@29cm/ruler';
import { ComponentProps } from 'react';

type Props = Pick<ComponentProps<'button'>, 'onClick' | 'disabled'> & {
  direction: 'previous' | 'next';
};

export const ProductCardCarouselNavigator = ({ disabled, direction, onClick }: Props) => {
  return (
    <IconButton
      icon={direction === 'previous' ? 'chevronLeft' : 'chevronRight'}
      priority="tertiary"
      size="small"
      disabled={disabled}
      className="h-[36px] min-h-[36px] w-[36px] min-w-[36px] !rounded-full disabled:cursor-not-allowed"
      onClick={onClick}
    />
  );
};
