import { Banner as BannerType } from '@29cm/contexts-banners-services';
import { getCampaignLink } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { Banner, BannerProps } from '@29cm/contexts-home/views';

interface Props {
  item: BannerType;
  priority?: BannerProps['priority'];
  position: number;
}

export const BannerCarouselItem = ({ item, priority, position }: Props) => {
  const { linkType, linkValue } = item;

  const href = getCampaignLink(linkType, linkValue);

  return (
    <Link href={href}>
      <div className="relative overflow-hidden rounded-6 bg-low">
        <Banner className="aspect-[3/4]" banner={item} priority={priority} position={position} />
        <div
          className="absolute inset-x-0 bottom-0 h-[44px] w-full"
          style={{
            background: 'linear-gradient(180deg, rgba(28, 28, 28, 0.00) 39.64%, rgba(28, 28, 28, 0.42) 100%)',
          }}
        />
      </div>
    </Link>
  );
};
