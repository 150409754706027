import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { getCampaignLink, getImageUrl } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { FeedProduct } from '@29cm/contexts-feeds-services';
import { ProductCardWithHeart } from '@29cm/contexts-products-features';

interface Props {
  item: FeedProduct;
}

export const ProductCardCarouselItem = ({ item }: Props) => {
  const { images, frontBrand, name, sale, heartCount, reviewCount, id } = item;
  const [image] = images;

  const imageUrl = image !== undefined ? `${getImageUrl(image.uploadName)}?width=600` : transparentFallbackImageSrc;
  const href = getCampaignLink('product', id);

  return (
    <Link href={href}>
      <ProductCardWithHeart
        productId={id}
        imgUrl={imageUrl}
        productName={name}
        price={sale.totalSellPrice}
        discount={sale.totalSaleRate}
        brandName={frontBrand.nameKor}
        likeAmount={heartCount}
        reviewAmount={reviewCount}
      />
    </Link>
  );
};
