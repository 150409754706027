import { Responsive } from '@29cm/contexts-common-views';

import { Feed } from '@29cm/contexts-feeds-services';
import { MultiProductCardCarousel } from '../MultiProductCardCarousel';
import { ProductCardCarousel } from '../ProductCardCarousel';

interface Props {
  item: Feed;
}

export const ProductCollectionFeed = ({ item }: Props) => {
  const { title, relatedProducts } = item;

  if (relatedProducts.length === 0) {
    return null;
  }

  return (
    <div className="md:px-20 md:py-[42px]">
      <p className="px-20 py-12 text-title-s-bold md:px-0 md:py-16 md:pr-[76px] md:text-title-l-bold">{title}</p>
      <Responsive
        sm={<MultiProductCardCarousel feed={item} items={relatedProducts} />}
        md={<ProductCardCarousel items={relatedProducts} />}
      />
    </div>
  );
};
