import { getCampaignLink } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { Feed } from '@29cm/contexts-feeds-services';
import { BannerFeedLayout } from '@29cm/contexts-feeds-views';

interface Props {
  item: Feed;
}

export const BannerFeed = ({ item }: Props) => {
  const href = getCampaignLink(item.linkType, item.linkValue);

  return (
    <Link href={href}>
      <BannerFeedLayout feed={item} />
    </Link>
  );
};
