import { Fetcher, Result } from '@29cm/contexts-http/interfaces';
import { useCallback, useEffect, useMemo } from 'react';
import { useCacheStore, usePromiseStore } from '../cache';

type UseCachedFetchOptions<T, E extends Error> = {
  ttl?: number;
  initialResult?: Result<T, E>;
};

type FetchStatus = 'pending' | 'refetching' | 'done';

type Cache<T, E extends Error> = {
  result?: Result<T, E>;
  status: FetchStatus;
};

const INITIAL_CACHE_TTL = 1000 * 60 * 5;

export const useCachedFetch = <O = unknown, T = unknown, E extends Error = Error>(
  fetcher: Fetcher<O, T, E>,
  fetcherOptions: O,
  options: UseCachedFetchOptions<T, E> = {},
) => {
  const { initialResult, ttl = INITIAL_CACHE_TTL } = options;

  const hasInitialResult = initialResult !== undefined;

  const initialValue = useMemo<Cache<T, E>>(
    () => ({
      status: hasInitialResult ? 'done' : 'pending',
      result: initialResult,
    }),
    [hasInitialResult, initialResult],
  );

  const key = useMemo(() => JSON.stringify([fetcher.key, fetcherOptions]), [fetcher.key, fetcherOptions]);

  const [cache, setCache] = useCacheStore<Cache<T, E>>(key, {
    ttl,
    initialValue,
  });

  const { batch } = usePromiseStore(key);

  const { status = 'pending', result } = cache ?? {};

  const hasResult = result !== undefined;

  const fetch = useCallback(async () => {
    const incoming = await fetcher(fetcherOptions);

    setCache({ status: 'done', result: incoming });
  }, [fetcher, fetcherOptions, setCache]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const batchedFetch = useCallback(batch(fetch), [fetch]);

  // TODO: refetch 실행 전, 진행 중인 요청 취소 처리
  const refetch = useCallback(() => {
    setCache({ status: 'refetching' });
    fetch();
  }, [fetch, setCache]);

  useEffect(() => {
    if (!hasResult) {
      setCache({ status: 'pending' });
      batchedFetch();
    }
    // NOTE: 마운트 시점과 key 가 변경된 시점에만 effect 를 실행합니다.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return {
    status,
    result,
    refetch,
  };
};
