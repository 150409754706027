import { entries } from '@29cm/utils-objects';
import { useCallback, useMemo, useSyncExternalStore } from 'react';
import { RemoveCookieOptions, SetCookieOptions } from '../interfaces';
import { createCookieGetter } from '../utils';
import { useCookieContext } from './useCookieContext';

export type SetCookieValue = {
  value: string | number | boolean;
  options?: SetCookieOptions;
};

export const useCookieStore = <T extends string>(keys: T[]) => {
  const { store, initialCookie } = useCookieContext();

  const setCookies = useCallback(
    (cookie: Record<T, SetCookieValue>) => {
      entries(cookie).forEach(([key, { value, options }]) => store.set(key, value, options));
    },
    [store],
  );

  // NOTE: keys 배열의 참조 변경을 방지하기 위해 memoize 합니다.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedKeys = useMemo(() => keys, [...keys]);

  const removeCookies = useCallback(
    (cookie: Record<T, RemoveCookieOptions>) => entries(cookie).forEach(([key, options]) => store.remove(key, options)),
    [store],
  );

  const subscribe = useCallback(
    (subscriber: () => void) => {
      const unsubscribes = memoizedKeys.map((key) => store.subscribe(key, subscriber));

      return () => {
        unsubscribes.forEach((unsubscribe) => unsubscribe());
      };
    },
    [memoizedKeys, store],
  );

  const getSnapshot = useCallback(() => {
    return JSON.stringify(memoizedKeys.map((key) => store.retrieve(key)));
  }, [memoizedKeys, store]);

  const getServerSnapshot = useCallback(() => {
    return JSON.stringify(memoizedKeys.map((key) => createCookieGetter(initialCookie)(key)));
  }, [memoizedKeys, initialCookie]);

  const cookie = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);

  const cookies = useMemo(() => JSON.parse(cookie) as string[], [cookie]);

  return {
    cookies,
    setCookies,
    removeCookies,
  };
};
