'use client';

import { createContext } from 'react';

export interface AuthenticationContextType {
  isAuthenticating: boolean;
  isLogged: boolean;
  setIsAuthenticating: (value: boolean) => void;
  logout: () => void;
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
  isAuthenticating: false,
  isLogged: false,
  setIsAuthenticating: () => {},
  logout: () => {},
});
