import { apiPrefixPath } from '@29cm/contexts-common-constants';

const env = apiPrefixPath ? `_${apiPrefixPath}` : '';

/**
 * _fuic_dev 로그인 아이디
 * _func_dev 로그인 사용자 이름
 * _funuc_dev 로그인 사용자 no
 * _fuec_dev 로그인 사용자 email
 * _fugc_dev 로그인 사용자 등급
 * _fugnc_dev 로그인 사용자 등급 이름
 * _ftwuid_dev API에서 사용할 로그인 정보
 * _fatc_dev 로그인 토큰
 * _fartc_dev 로그인 토큰 갱신을 위한 토큰
 * _focuc_dev 비회원 장바구니 Key
 * _fosc_dev 비회원 주문조회용 주문번호 쿠키
 * _fucc_dev 회원 크리에이터 번호
 * _fugdc_dev 회원 성별
 */
export const COOKIE_USER_KEYS = {
  ID: `_fuic${env}`,
  NAME: `_func${env}`,
  USER_NO: `_funuc${env}`,
  CREATE_NO: `_fucc${env}`,
  EMAIL: `_fuec${env}`,
  GRADE: `_fugc${env}`,
  GRADE_NAME: `_fugnc${env}`,
  ACCESS_TOKEN: `_fatc${env}`,
  REFRESH_TOKEN: `_fartc${env}`,
  GENDER: `_fugdc${env}`,
  API: `_ftwuid${env}`,
};
