export const createCookieGetter = (cookie: string) => (key: string) => {
  return decodeURIComponent(
    cookie.replace(
      new RegExp(
        `(?:(?:^|.*;)\\s*${encodeURIComponent(key).replace(
          // eslint-disable-next-line no-useless-escape
          /[\-\.\+\*]/g,
          '\\$&',
        )}\\s*\\=\\s*([^;]*).*$)|^.*$`,
      ),
      '$1',
    ),
  );
};
