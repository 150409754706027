import * as v from 'valibot';

const RESULT = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
} as const;

export const ApiResponse = v.object({
  result: v.enum(RESULT),
  errorCode: v.nullable(v.string()),
  message: v.nullable(v.string()),
});

export type ApiResponse = v.InferInput<typeof ApiResponse>;
