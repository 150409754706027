import { Result } from '../interfaces';

type GetResultData = {
  <T, E extends Error>(result: Result<T, E> | undefined): T | undefined;
  <T, E extends Error>(result: Result<T, E> | undefined, fallback: T): T;
};

export const getResultData: GetResultData = <T, E extends Error>(result: Result<T, E> | undefined, fallback?: T) => {
  return result?.success ? result.data : fallback;
};
