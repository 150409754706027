import { useEffect } from 'react';

/**
 * `body` 요소의 스크롤을 잠그거나 해제하는 커스텀 훅입니다.
 *
 * @example
 * ```tsx
 * const [isLocked, setIsLocked] = useState(true);
 *
 * useBodyScrollLock(isLocked);
 *
 * const handler = () => {
 *   setIsLocked(true); // 스크롤 잠금 활성화
 *   setIsLocked(false); //  스크롤 잠금 비활성화
 * }
 * ```
 */
export const useBodyScrollLock = (isLocked: boolean): void => {
  useEffect(() => {
    const element = document.body;

    if (!isLocked || !element) {
      return;
    }

    element.classList.add('disable-scroll');

    return () => {
      element.classList.remove('disable-scroll');
    };
  }, [isLocked]);
};
