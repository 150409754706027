import { tv } from 'tailwind-variants';

const dividerStyles = tv({
  base: 'm-0 border-l border-solid border-line',
  variants: {
    direction: {
      vertical: 'h-full w-[1px]',
      horizontal: 'h-[1px] w-full',
    },
  },
});

interface Props {
  className?: string;
  direction: 'vertical' | 'horizontal';
}

export const Divider = ({ className, direction }: Props) => {
  return <hr className={dividerStyles({ direction, class: className })} />;
};
