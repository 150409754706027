import * as v from 'valibot';

export const HomePcBannerListResponse = v.object({
  count: v.number(),
  next: v.nullable(v.string()),
  previous: v.nullable(v.string()),
  results: v.array(
    v.object({
      admin_no: v.number(),
      banner_contents: v.string(),
      banner_contents_for_logging: v.string(),
      banner_division: v.string(),
      banner_no: v.number(),
      banner_title: v.string(),
      banner_title_for_logging: v.string(),
      contents_color: v.string(),
      gender: v.nullable(v.string()),
      insert_timestamp: v.string(),
      is_banner_title_hidden: v.string(),
      is_deleted: v.string(),
      is_use: v.string(),
      is_view_end: v.string(),
      layout_column: v.number(),
      link_type: v.string(),
      link_value: v.string(),
      ordering_idx: v.number(),
      related_banner_category: v.array(v.object({})),
      related_banner_images: v.array(
        v.object({
          file_extension: v.string(),
          file_size: v.number(),
          file_upload_name: v.string(),
          height_no: v.number(),
          is_deleted: v.string(),
          width_no: v.number(),
        }),
      ),
      updated_timestamp: v.string(),
      view_end_timestamp: v.nullable(v.string()),
      view_start_timestamp: v.string(),
      visit_count: v.number(),
    }),
  ),
});

export type HomePcBannerListResponse = v.InferInput<typeof HomePcBannerListResponse>;
