import { COOKIE_USER_KEYS } from '@29cm/contexts-cookie/constants';
import { useCookieStore } from '@29cm/contexts-cookie/hooks';
import { User } from '../../interfaces';
import { validateUser } from '../../utils';
import { decodeUser } from '../../utils/decodeUser';
import { RemoveCookieOptions } from '@29cm/contexts-cookie/interfaces';
import { values } from '@29cm/utils-objects';

/**
 * `UserProvider`에서 사용되는 훅으로 사용자 정보가 필요한 경우, `UserProvider`에서 유저 정보를 얻어 사용합니다.
 * @returns {User | undefined} 유효한 사용자 정보가 있는 경우 `User` 객체를 반환하며, 그렇지 않으면 `undefined`를 반환합니다.
 */
export const useCurrentUser = (): { currentUser: User | undefined; clear: () => void } => {
  const { ID, NAME, USER_NO, CREATE_NO, EMAIL, GRADE, GRADE_NAME, GENDER, ACCESS_TOKEN, REFRESH_TOKEN } =
    COOKIE_USER_KEYS;

  const {
    cookies: [id, name, no, creatorNo, email, grade, gradeName, gender, accessToken, refreshToken],
    removeCookies,
  } = useCookieStore([ID, NAME, USER_NO, CREATE_NO, EMAIL, GRADE, GRADE_NAME, GENDER, ACCESS_TOKEN, REFRESH_TOKEN]);

  const parsable = [accessToken, id, name, email, no, refreshToken].every((value) => value !== '');

  const clear = () => {
    removeCookies(
      values(COOKIE_USER_KEYS).reduce(
        (prev, curr) => ({ ...prev, [curr]: { domain: '.29cm.co.kr', path: '/' } }),
        {} as Record<string, RemoveCookieOptions>,
      ),
    );
  };

  if (!parsable) {
    return { currentUser: undefined, clear };
  }

  const user = decodeUser({
    id,
    name,
    no,
    creatorNo,
    email,
    grade,
    gradeName,
    gender,
    accessToken,
    refreshToken,
  });

  return { currentUser: validateUser(user), clear };
};
