import { useAuthenticationContext } from '@29cm/contexts-auth/features';
import { showLoginDialog } from '@29cm/contexts-auth/utils';
import {
  ProductHorizontalCard,
  ProductHorizontalCardHeartPostfix,
  ProductHorizontalCardPostfixType,
  ProductHorizontalCardProps,
} from '@29cm/contexts-common-ruler';
import { useProductHeart } from '@29cm/contexts-hearts-features';
import { memo } from 'react';

export interface ProductHorizontalCardWithHeartProps extends Omit<ProductHorizontalCardWithLoginProps, 'postfix'> {
  likeAmount: ProductHorizontalCardHeartPostfix['likeAmount'];
}

export const ProductHorizontalCardWithHeart = memo(({ likeAmount, ...props }: ProductHorizontalCardWithHeartProps) => {
  const { isLogged } = useAuthenticationContext();

  const commonHeartPostfixProps: ProductHorizontalCardWithLoginProps['postfix'] = {
    type: 'likeCountButton',
    likeAmount: likeAmount,
  };

  if (isLogged) {
    return <ProductHorizontalCardWithLogin {...props} postfix={commonHeartPostfixProps} />;
  }

  return (
    <ProductHorizontalCard
      {...props}
      postfix={{ ...commonHeartPostfixProps, onHeart: false, onClick: showLoginDialog }}
    />
  );
});

interface ProductHorizontalCardWithLoginProps extends Omit<ProductHorizontalCardProps, 'postfix'> {
  productId: number;
  postfix: Omit<ProductHorizontalCardHeartPostfix, 'onHeart' | 'onClick'>;
}

const ProductHorizontalCardWithLogin = memo(
  ({ productId, postfix: basePostfix, ...rest }: ProductHorizontalCardWithLoginProps) => {
    const { isHeartOn, heartCount, toggleHeart } = useProductHeart(productId, basePostfix.likeAmount ?? 0);

    const postfix: ProductHorizontalCardPostfixType = {
      ...basePostfix,
      onHeart: isHeartOn,
      likeAmount: heartCount,
      onClick: toggleHeart,
    };

    return <ProductHorizontalCard {...rest} postfix={postfix} />;
  },
);
