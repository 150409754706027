import { Badge, LikeButton } from '@29cm/ruler';
import { useCallback, useMemo } from 'react';

export interface PreviewProps {
  imgUrl: string;
  onHeart: boolean;
  onHeartClick?: () => void;
  extraBadge?: string;
}

export const Preview = ({ onHeart = false, onHeartClick, imgUrl, extraBadge }: PreviewProps) => {
  const handleHeartClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault(); // 기본 동작 방지 (e.g. 부모 요소 링크 이동)
      e.stopPropagation();
      onHeartClick?.();
    },
    [onHeartClick],
  );

  return (
    <div className="preview">
      <img className="object-fit aspect-square w-full rounded-2" src={imgUrl} loading="lazy" />
      <div className="relative">
        {extraBadge && <ExtraBadge label={extraBadge} />}
        {/* TODO: Design Token 확인이 어려움 */}
        <div className="absolute -bottom-2 -right-10">
          <LikeButton on={onHeart} priority="white" size="large" transparent={true} onClick={handleHeartClick} />
        </div>
      </div>
    </div>
  );
};

const ExtraBadge = ({ label }: { label: string }) => {
  return useMemo(() => {
    return (
      <div className="absolute bottom-10 left-10">
        <Badge size="medium" priority="primary">
          {label}
        </Badge>
      </div>
    );
  }, [label]);
};
