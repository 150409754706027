import { createPromiseStore } from '@29cm/contexts-common-stores';
import { Fetcher, Result } from '@29cm/contexts-http/interfaces';
import { useEffectOnce, useUpdateEffect } from '@29cm/hooks-effects';
import { useCallback, useMemo, useState } from 'react';
import { FetchStatus } from '../interfaces';

type UseCachedFetchOptions<T, E extends Error> = {
  initialResult?: Result<T, E>;
};

export const useFetch = <O = unknown, T = unknown, E extends Error = Error>(
  fetcher: Fetcher<O, T, E>,
  fetcherOptions: O,
  options: UseCachedFetchOptions<T, E> = {},
) => {
  const { initialResult } = options;

  const hasInitialResult = initialResult !== undefined;

  const [status, setStatus] = useState<FetchStatus>(hasInitialResult ? 'done' : 'pending');
  const [result, setResult] = useState<Result<T, E> | undefined>(initialResult);

  // NOTE: 참조 타입일 수 있는 fetcherOptions 를 원시 타입으로 변환하여 변경을 감지할 수 있도록 합니다.
  const key = useMemo(() => JSON.stringify(fetcherOptions), [fetcherOptions]);

  const { batch } = useMemo(() => createPromiseStore(key), [key]);

  const fetch = useCallback(async () => {
    const incoming = await fetcher(fetcherOptions);

    setStatus('done');
    setResult(incoming);
  }, [fetcher, fetcherOptions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const batchedFetch = useCallback(batch(fetch), [fetch]);

  // TODO: refetch 실행 전, 진행 중인 요청 취소 처리
  const refetch = useCallback(() => {
    setStatus('refetching');
    fetch();
  }, [fetch]);

  useEffectOnce(() => {
    if (hasInitialResult) {
      return;
    }

    setStatus('pending');
    batchedFetch();
  });

  useUpdateEffect(() => {
    setStatus('pending');
    batchedFetch();
    // NOTE: key 가 변경된 시점에만 effect 를 실행합니다.
  }, [key]);

  return {
    status,
    result,
    refetch,
  };
};
