interface Props {
  current: number;
  max: number;
}

export const ProductCardCarouselProgress = ({ current, max }: Props) => {
  return (
    <div className="inline-flex items-center">
      <p className="text-[34px] leading-[136%]">{current}</p>
      <p className="flex h-[64px] flex-col justify-end text-[34px] leading-[136%] opacity-20">/{max}</p>
    </div>
  );
};
