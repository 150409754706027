import NextLink from 'next/link';
import React, { ComponentProps, PropsWithChildren } from 'react';

type AvailableNextLinkProps = Omit<ComponentProps<typeof NextLink>, 'as'>;

export interface LinkProps extends AvailableNextLinkProps {
  href: string;
}

export const Link: React.FC<PropsWithChildren<LinkProps>> = (props) => {
  return <NextLink {...props} />;
};
