import { imageHost } from '@29cm/contexts-common-constants';
import { urlJoin } from '@29cm/utils-url';

/**
 * pathname 형태가 pathname 인 경우, 29CM 이미지 서버의 호스트를 기반으로 일관된 fullImageUrl 을 생성하기 위해 사용 합니다.
 * @param {string} pathname - pathname 형태의 이미지 pathname
 * @returns {string} 생성된 fullImageURL
 */
export const getImageUrl = (pathname: string) => {
  return pathname.includes('https://') ? pathname : urlJoin(`https://${imageHost}`, pathname);
};
