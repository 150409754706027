import { forwardRef, memo } from 'react';
import { tv } from 'tailwind-variants';
import { BrandCardProps } from './BrandCard.types';
import BrandCardAction from './BrandCardAction';
import BrandCardInfo from './BrandCardInfo';

const brandCard = tv({
  slots: {
    base: 'relative flex cursor-pointer items-center rounded-2',
    image: '-mr-2 aspect-square object-cover',
    info: 'min-w-0 grow',
    action: 'h-full shrink-0',
    outline: '',
  },
  variants: {
    size: {
      small: {
        base: 'h-48 gap-x-10',
        image: 'w-48',
      },
      medium: {
        base: 'h-72 gap-x-16',
        image: 'w-72',
      },
    },
    isOutlined: {
      true: {
        image: 'z-[1] rounded-l-2',
        info: 'z-[1]',
        action: 'z-[1]',
        outline: 'absolute z-[0] h-full w-full rounded-2 border border-gray-200',
      },
      false: {
        image: 'rounded-2',
      },
    },
  },
  compoundSlots: [
    // base (by isOutlined=true, size)
    { slots: ['base'], isOutlined: true, size: 'small', class: 'pr-6' },
    { slots: ['base'], isOutlined: true, size: 'medium', class: 'pr-8' },
  ],
});

const BrandCard = forwardRef<HTMLDivElement, BrandCardProps>(
  ({
    className,
    size = 'medium',
    variant = 'default',
    brandImage,
    onClick,
    isOutlined,
    koreanName,
    englishName,
    ...props
  }: BrandCardProps) => {
    const style = brandCard({
      className,
      size,
      isOutlined,
    });

    return (
      <div className={style.base()} onClick={onClick} role="link">
        {brandImage ? (
          <img
            className={style.image()}
            src={brandImage}
            role="img"
            loading="lazy"
            draggable="false"
            alt="Brand Card Image"
          />
        ) : isOutlined ? (
          <span />
        ) : null}

        <div className={style.info()}>
          <BrandCardInfo englishName={englishName} koreanName={koreanName} size={size} variant={variant} />
        </div>
        <div className={style.action()}>
          <BrandCardAction size={size} variant={variant} {...props} />
        </div>
        {isOutlined && <div className={style.outline()} />}
      </div>
    );
  },
);

export default memo(BrandCard);
