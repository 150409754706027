/* eslint-disable @typescript-eslint/no-explicit-any */

import { showLoginDialog } from '@29cm/contexts-auth/utils';
import { useAuthenticationContext } from '.';

export const useWithLogin = (message?: string) => {
  const { isLogged, isAuthenticating } = useAuthenticationContext();

  const withLogin =
    <T extends (...args: any[]) => any>(callback: T) =>
    async (...args: Parameters<T>): Promise<void> => {
      if (isAuthenticating) {
        return;
      }

      if (!isLogged) {
        showLoginDialog(message);
        return;
      }

      await callback(...args);
    };

  return {
    withLogin,
  };
};
