import * as v from 'valibot';

export type AFValues = {
  keys?: string[];
  paramKey?: string;
  defaultValue?: string;
};

export type AFParameters<T> = {
  mediaSource: T;
  channel: T;
  campaign: T;
  afCustom: T[];
  ad: T;
  adSet: T;
};

export type GenerateOneLinkURLParams = {
  oneLinkURL: string;
  webReferrer: string;
  afParameters: AFParameters<AFValues>;
};

export type GenerateOneLinkURL = (arg: GenerateOneLinkURLParams) => { clickURL: string };

export const AppsFlyerSmartScript = v.object({
  generateOneLinkURL: v.function(),
});

export interface AfSmartScriptInstance {
  AF_SMART_SCRIPT?: {
    generateOneLinkURL: GenerateOneLinkURL;
  };
}

export type OneLinkContent = 'TOP_BANNER' | 'INSTALL_LAYER';
