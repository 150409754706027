import { makeSizesString } from '@29cm/contexts-common-utils';
import Image, { ImageProps } from 'next/image';

export interface ResponsiveImageProps extends Pick<ImageProps, 'src' | 'alt' | 'quality' | 'fill' | 'priority'> {
  sizes?: {
    sm: string;
    md?: string;
    lg?: string;
  };
  className?: string;
}

export const ResponsiveImage = ({
  className,
  src,
  alt,
  quality = 75,
  sizes,
  fill,
  priority = false,
}: ResponsiveImageProps) => {
  return (
    <Image
      className={className}
      src={src}
      alt={alt}
      sizes={makeSizesString(sizes)}
      quality={quality}
      fill={fill}
      priority={priority}
    />
  );
};
