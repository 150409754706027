import { FetcherKey, apihubApiPath } from '@29cm/contexts-common-constants';
import { createFetcher } from '@29cm/contexts-http/services';
import { urlJoin } from '@29cm/utils-url';
import * as v from 'valibot';
import { HeartList } from '../models';
import { SetHeartResponse } from '../response-models';

export const fetchHeartListByType = createFetcher<{ type: 'product' | 'brand' | 'post' }, HeartList>({
  method: 'GET',
  url: ({ type }) => `${urlJoin(apihubApiPath, `/heart/${type}`)}/items/`,
  validator: (data) => v.safeParse(HeartList, data).issues?.map((i) => i.message),
  key: FetcherKey.FetchUserHeartList,
  requestOption: {
    credentials: 'include',
  },
});

export const fetchSetHeart = createFetcher<
  { type: 'product' | 'brand' | 'post'; target: number | string },
  SetHeartResponse
>({
  method: 'GET',
  url: ({ type, target }) => `${urlJoin(apihubApiPath, `/heart/${type}/${target}`)}/set/`,
  validator: (data) => v.safeParse(SetHeartResponse, data).issues?.map((i) => i.message),
  key: FetcherKey.FetchSetHeart,
  requestOption: {
    credentials: 'include',
  },
});

export const fetchUnsetHeart = createFetcher<{ type: 'product' | 'brand' | 'post'; target: number | string }, unknown>({
  method: 'GET',
  url: ({ type, target }) => `${urlJoin(apihubApiPath, `/heart/${type}/${target}`)}/unset/`,
  validator: () => undefined,
  key: FetcherKey.FetchUnsetHeart,
  requestOption: {
    credentials: 'include',
  },
});
