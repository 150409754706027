import { wrapApiResponse } from '@29cm/contexts-http/utils';
import * as v from 'valibot';

export const HomeMobileBannerListResponse = wrapApiResponse(
  v.object({
    count: v.number(),
    bannerList: v.array(
      v.object({
        bannerId: v.number(),
        bannerDivision: v.string(),
        bannerTitle: v.string(),
        bannerContents: v.string(),
        linkType: v.string(),
        linkValue: v.string(),
        viewStartAt: v.string(),
        viewEndAt: v.string(),
        contentsColor: v.string(),
        isBannerTitleHidden: v.boolean(),
        bannerImageList: v.array(
          v.object({
            fileUploadName: v.string(),
            fileSize: v.number(),
            fileExtension: v.string(),
            width: v.number(),
            height: v.number(),
          }),
        ),
        bannerValue: v.nullable(v.string()),
        bannerTypeDetail: v.string(),
      }),
    ),
  }),
);

export type HomeMobileBannerListResponse = v.InferInput<typeof HomeMobileBannerListResponse>;
