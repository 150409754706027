import { FetcherKey, contentApiPath } from '@29cm/contexts-common-constants';
import { createFetcher } from '@29cm/contexts-http/services';
import { booleanize } from '@29cm/contexts-http/utils';
import { urlJoin } from '@29cm/utils-url';
import * as v from 'valibot';
import { HomeType } from '../enums';
import { Feeds } from '../models';
import { FeedsResponse } from '../response-models';

type FeedsOptions = {
  homeType: HomeType;
  limit: number;
  offset: number;
};

export const fetchFeeds = createFetcher<FeedsOptions, FeedsResponse, Feeds>({
  key: FetcherKey.FetchFeeds,
  method: 'GET',
  url: ({ limit, offset, homeType }) =>
    `${urlJoin(contentApiPath, '/api/v5/feeds')}/?feed_sort=null&limit=${limit}&offset=${offset}&home_type=${homeType}`,
  validator: (data) => v.safeParse(FeedsResponse, data).issues?.map((issue) => issue.message),
  transformer: ({ data }) => ({
    // TODO: data.result === "FAIL" 인 케이스 대응하기
    ...data!,
    results: data!.results.map((result) => ({
      id: result.feedNo,
      type: result.feedType,
      viewStartAt: result.viewStartTimestamp,
      viewEndAt: result.viewEndTimestamp,
      isViewEnd: booleanize(result.isViewEnd),
      homeType: result.homeType,
      title: result.feedTitle,
      contents: result.feedContents,
      linkType: result.linkType,
      linkValue: result.linkValue,
      createdAt: result.insertTimestamp,
      updatedAt: result.updatedTimestamp,
      isDeleted: booleanize(result.isDeleted),
      isUse: booleanize(result.isUse),
      orderingIndex: result.orderingIdx,
      relatedFiles: result.relatedFeedFileList.map((file) => ({
        id: file.fileNo,
        size: file.fileSize,
        extension: file.fileExtension,
        uploadName: file.fileUploadName,
        createdAt: file.insertTimestamp,
        isDeleted: booleanize(file.isDeleted),
        width: file.widthNo,
        height: file.heightNo,
        ratioType: file.alignNo,
      })),
      relatedProducts: result.relatedFeedItemList.map(
        ({
          feedItemNo: feedProductId,
          itemNo: product,
          insertTimestamp: createdAt,
          updatedTimestamp: updatedAt,
          alignNo: ratioType,
          isDeleted,
        }) => ({
          feedProductId,
          id: product.itemNo,
          name: product.itemName,
          isLocalGoods: booleanize(product.isLocalGoods),
          heartCount: product.heartCount,
          reviewCount: product.reviewCount,
          sale: {
            consumerPrice: product.saleInfoV2.consumerPrice,
            sellPrice: product.saleInfoV2.sellPrice,
            saleRate: product.saleInfoV2.saleRate,
            couponSaleRate: product.saleInfoV2.couponSaleRate,
            isCouponAvailable: product.saleInfoV2.isCoupon,
            totalSellPrice: product.saleInfoV2.totalSellPrice,
            totalSaleRate: product.saleInfoV2.totalSaleRate,
          },
          frontBrand: {
            id: product.frontBrand.frontBrandNo,
            nameEng: product.frontBrand.brandNameEng,
            nameKor: product.frontBrand.brandNameKor,
          },
          images: product.itemImages.map((image) => ({
            width: image.widthNo,
            height: image.heightNo,
            imageType: image.imageType,
            uploadName: image.fileUploadName,
          })),
          subjects: product.itemSubjects.map((subject) => ({
            id: subject.itemSubjectNo,
            displayStartAt: subject.subjectVisibleBeginTimestamp,
            displayEndAt: subject.subjectVisibleEndTimestamp,
            isWithoutDisplayEndAt: booleanize(subject.useWithoutSubjectVisibleEndDate),
            externalServiceType: subject.externalServiceType,
            description: subject.subjectDescription,
            orderingIndex: subject.orderingIdx,
          })),
          createdAt,
          updatedAt,
          ratioType,
          isDeleted: booleanize(isDeleted),
        }),
      ),
    })),
  }),
});
