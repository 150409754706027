import { alertDialog } from '@29cm/ruler';

type emoAlertDialogShowParams = Parameters<typeof alertDialog.show>[0];

export const emoAlertDialog: {
  show: (
    params: Omit<emoAlertDialogShowParams, 'preventScroll'> & {
      /**
       * - `loose`: `body`에 `disable-scroll` 클래스를 추가하여 `body`의 `overflow` 속성만 제어하여 스크롤을 방지 합니다.
       * - `strict`: `body`의 `overflow` 속성과 함께 `position`, `top`, `left`, `right` 속성을 함께 제어하여 스크롤을 방지 합니다. (기본 값)
       */
      preventScroll?: 'loose' | 'strict';
    },
  ) => ReturnType<typeof alertDialog.show>;
  hide: typeof alertDialog.hide;
} = {
  show: ({ preventScroll = 'strict', ...params }) => {
    if (preventScroll === 'loose') {
      document.body.classList.add('disable-scroll');
    }

    const onConfirm = () => {
      if (preventScroll === 'loose') {
        document.body.classList.remove('disable-scroll');
      }

      params.onConfirm?.();
    };

    const onCancel = () => {
      if (preventScroll === 'loose') {
        document.body.classList.remove('disable-scroll');
      }

      params.onCancel?.();
    };

    alertDialog.show({
      ...params,
      onConfirm,
      onCancel,
      preventScroll: preventScroll === 'strict',
    });
  },
  hide: alertDialog.hide,
};
