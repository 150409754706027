'use client';

import { AnimationPlaybackControls, animate, useMotionValueEvent, useScroll, useTransform } from 'framer-motion';
import { CSSProperties, ReactNode, forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { CarouselItem } from './CarouselItem';

export interface CarouselRef {
  focusTo: (index: number, animated?: boolean) => void;
}

interface Props<T extends { id: string | number } = { id: string | number }> {
  className?: string;
  itemClassName?: string;
  items: ReadonlyArray<T>;
  alignItems?: CSSProperties['alignItems'];
  snapAlign?: CSSProperties['scrollSnapAlign'];
  snapStop?: CSSProperties['scrollSnapStop'];
  itemWidth?: {
    sm: string;
    md?: string;
  };
  verticalPadding?: number;
  horizontalPadding?: {
    sm: string;
    md?: string;
  };
  gap?: number;
  itemsPerView?: number;
  renderItem: (item: T, index: number) => ReactNode;
  onScroll?: (scrollXProgress: number) => void;
  onFocusedIndexChange?: (focusedIndex: number) => void;
}

const CarouselComponent = forwardRef<CarouselRef, Props>(
  (
    {
      className,
      itemClassName,
      items,
      alignItems = 'center',
      snapAlign = 'center',
      snapStop,
      itemWidth = { sm: '100%' },
      verticalPadding = 0,
      horizontalPadding = { sm: '0px' },
      gap = 0,
      itemsPerView,
      renderItem,
      onScroll,
      onFocusedIndexChange,
    },
    ref,
  ) => {
    const slideContainerRef = useRef<HTMLDivElement | null>(null);
    const animationRef = useRef<AnimationPlaybackControls>();
    const { scrollXProgress } = useScroll({ container: slideContainerRef });
    const currentFocusedIndex = useTransform(scrollXProgress, (input) => Math.round(input * (items.length - 1)));

    useMotionValueEvent(currentFocusedIndex, 'change', (latest) => {
      onFocusedIndexChange?.(latest);
    });

    useMotionValueEvent(scrollXProgress, 'change', (latest) => {
      onScroll?.(latest);
    });

    const disableScrollSnap = useCallback(() => {
      slideContainerRef.current?.classList.add('!snap-none');
    }, []);

    const enableScrollSnap = useCallback(() => {
      slideContainerRef.current?.classList.remove('!snap-none');
    }, []);

    useImperativeHandle(ref, () => ({
      focusTo: async (index: number, animated: boolean = true) => {
        const containerElement = slideContainerRef.current;
        if (containerElement === null) {
          return;
        }

        const ratioAdjustment = itemsPerView ? 1 / itemsPerView : 1;

        const to =
          (containerElement.scrollWidth - containerElement.clientWidth * ratioAdjustment) *
          (index / (items.length - 1));

        disableScrollSnap();
        animationRef.current?.stop();
        if (animated) {
          const from = containerElement.scrollLeft;
          animationRef.current = await animate(from, to, {
            type: 'spring',
            stiffness: 200,
            damping: 30,
            onUpdate: (latest) => {
              containerElement.scrollLeft = latest;
            },
          });
        } else {
          containerElement.scrollLeft = to;
        }
        enableScrollSnap();
      },
      scrollTo: async (scrollXProgress: number, animated: boolean) => {
        const containerElement = slideContainerRef.current;
        if (containerElement === null) {
          return;
        }

        const to = (containerElement.scrollWidth - containerElement.clientWidth) * scrollXProgress;

        disableScrollSnap();
        animationRef.current?.stop();
        if (animated) {
          const from = containerElement.scrollLeft;
          animationRef.current = await animate(from, to, {
            type: 'spring',
            stiffness: 200,
            damping: 30,
            onUpdate: (latest) => {
              containerElement.scrollLeft = latest;
            },
          });
        } else {
          containerElement.scrollLeft = to;
        }
        enableScrollSnap();
      },
    }));

    return (
      <div
        ref={slideContainerRef}
        className={twMerge(
          'scrollbar-hide flex snap-x snap-mandatory scroll-px-[--horizontal-padding-sm] overflow-x-auto px-[--horizontal-padding-sm] md:scroll-px-[--horizontal-padding-md] md:px-[--horizontal-padding-md]',
          className,
        )}
        style={{
          alignItems,
          gap,
          paddingTop: verticalPadding,
          paddingBottom: verticalPadding,
          ...{
            '--horizontal-padding-sm': horizontalPadding.sm,
            '--horizontal-padding-md': horizontalPadding.md ?? horizontalPadding.sm,
          },
        }}
      >
        {items.map((item, index) => (
          <CarouselItem
            // 원칙적으로 item.id 가 유니크 해야 하나, 상품 아이디 등의 중복 케이스를 대응하기 위해 index와 조합 합니다.
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${item.id}`}
            focused={currentFocusedIndex.get() === index}
            className={itemClassName}
            item={item}
            index={index}
            snapAlign={snapAlign}
            snapStop={snapStop}
            itemWidth={itemsPerView ? { sm: `${calcItemWidthRatio(itemsPerView)}%` } : itemWidth}
            renderItem={renderItem}
          />
        ))}
      </div>
    );
  },
);

const calcItemWidthRatio = (ratio: number) => {
  return Math.round((1 / ratio) * 100);
};

export const Carousel = memo(CarouselComponent) as <T extends { id: string | number }>(
  props: Props<T> & { ref?: React.ForwardedRef<CarouselRef> },
) => ReturnType<typeof CarouselComponent>;
