import { useContext } from 'react';
import { UserContext } from '../contexts';

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('UserContext should be used within Root');
  }

  return context;
};
