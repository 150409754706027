import { FetcherKey, apihubApiPath, apihubCacheApiPath, appEnv, contentApiPath } from '@29cm/contexts-common-constants';
import { createFetcher } from '@29cm/contexts-http/services';
import { urlJoin } from '@29cm/utils-url';
import * as v from 'valibot';
import { Banners } from '../models';
import { HomeMobileBannerListResponse, HomePcBannerListResponse } from '../response-models';

export const fetchHomePcBannerList = createFetcher<
  { limit: number; offset: number },
  HomePcBannerListResponse,
  Banners
>({
  method: 'GET',
  url: ({ limit, offset }) =>
    `${urlJoin(appEnv === 'development' ? apihubApiPath : apihubCacheApiPath, '/main-guide')}/?limit=${limit}&offset=${offset}&division=homepc&status=T`,
  validator: (data) => v.safeParse(HomePcBannerListResponse, data).issues?.map((i) => i.message),
  key: FetcherKey.FetchHomePcBannerList,
  transformer: (data) => {
    return {
      count: data.count,
      next: data.next,
      previous: data.previous,
      results: data.results.map((banner) => ({
        bannerId: banner.banner_no,
        bannerDivision: banner.banner_division,
        bannerTitle: banner.banner_title,
        bannerContents: banner.banner_contents,
        linkType: banner.link_type,
        linkValue: banner.link_value,
        contentsColor: banner.contents_color,
        // NOTE: 데스크탑 배너는 타이틀이 없으므로 해당 플래그의 default value 를 true 로 설정
        isBannerTitleHidden: true,
        bannerImageList: banner.related_banner_images.map((image) => ({
          fileUploadName: image.file_upload_name,
          width: image.width_no,
          height: image.height_no,
        })),
      })),
    };
  },
});

export const fetchHomeMobileBannerList = createFetcher<{ size: number }, HomeMobileBannerListResponse, Banners>({
  method: 'GET',
  url: ({ size }) => `${urlJoin(contentApiPath, `/api/v4/banners`)}/?size=${size}&bannerDivision=HOME_MOBILE`,
  validator: (data) => v.safeParse(HomeMobileBannerListResponse, data).issues?.map((i) => i.message),
  key: FetcherKey.FetchHomeMobileBannerList,
  transformer: ({ result, data }) => {
    // TODO: 200 'FAIL' middleware 에서 처리
    if (result === 'FAIL') {
      return {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
    }

    return {
      count: data!.count,
      next: null,
      previous: null,
      results: data!.bannerList.map((banner) => ({
        bannerId: banner.bannerId,
        bannerDivision: banner.bannerDivision.toLowerCase(),
        bannerTitle: banner.bannerTitle,
        bannerContents: banner.bannerContents,
        linkType: banner.linkType.toLowerCase(),
        linkValue: banner.linkValue,
        contentsColor: banner.contentsColor.toLowerCase(),
        isBannerTitleHidden: banner.isBannerTitleHidden,
        bannerImageList: banner.bannerImageList.map((image) => ({
          fileUploadName: image.fileUploadName,
          width: image.width,
          height: image.height,
        })),
      })),
    };
  },
});
