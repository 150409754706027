import * as v from 'valibot';

export const SetHeartResponse = v.object({
  heart_no: v.number(),
  heart_type: v.string(),
  insert_timestamp: v.string(),
  service_no: v.number(),
  user_no: v.number(),
});

export type SetHeartResponse = v.InferInput<typeof SetHeartResponse>;
