'use client';

import { useContext } from 'react';
import { AuthenticationContext } from '../contexts/AuthenticationContext';

export const useAuthenticationContext = () => {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error('AuthenticationContext should be used within Root');
  }

  return context;
};
