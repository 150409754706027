'use client';

import { createContext } from 'react';
import { User } from '../../interfaces';

interface UserContextType {
  user?: User;
  clear: () => void;
}

export const UserContext = createContext<UserContextType>({ user: undefined, clear: () => {} });
