import { RefObject, useEffect, useState } from 'react';

export type ViewChangeHandler = (entry: IntersectionObserverEntry) => void;

export type UseInViewOptions = {
  root?: RefObject<Element>;
  rootMargin?: string;
  threshold?: number | number[];
  once?: boolean;
  initial?: boolean;
};

export const useInView = (target: RefObject<Element>, options: UseInViewOptions = {}, callback?: ViewChangeHandler) => {
  const { initial = false, once = false, root, rootMargin, threshold } = options;
  const [isInView, setIsInView] = useState(initial);

  useEffect(() => {
    const element = target.current;

    if (element === null) {
      return;
    }

    if (once && isInView) {
      return;
    }

    const onViewChange: IntersectionObserverCallback = ([entry]) => {
      if (entry === undefined) {
        return;
      }

      if (once && entry.isIntersecting) {
        observer.unobserve(element);
      }

      setIsInView(entry.isIntersecting);
      callback?.(entry);
    };

    const observer = new IntersectionObserver(onViewChange, {
      root: root ? root.current : null,
      rootMargin,
      threshold,
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [rootMargin, threshold, target, once, root, callback, isInView]);

  return isInView;
};
