import * as v from 'valibot';

export const FeedProductFrontBrand = v.object({
  id: v.number(),
  nameEng: v.string(),
  nameKor: v.string(),
});

export type FeedProductFrontBrand = {
  id: number;
  nameEng: string;
  nameKor: string;
};
