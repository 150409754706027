'use client';

import { fetchLogout } from '@29cm/contexts-auth/services';
import { useUserContext } from '@29cm/contexts-users/features';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { AuthenticationContext, AuthenticationContextType } from './AuthenticationContext';

export const AuthenticationProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const { user, clear } = useUserContext();

  const logout = useCallback(async () => {
    // TODO(byungcheollee): 로그아웃 다이얼로그 띄우기
    await fetchLogout({});
    clear();
    window.location.reload();
  }, [clear]);

  const value: AuthenticationContextType = useMemo(
    () => ({
      isAuthenticating,
      isLogged: !isAuthenticating && user !== undefined,
      setIsAuthenticating,
      logout,
    }),
    [isAuthenticating, logout, user],
  );

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};
