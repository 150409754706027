import { Banner as BannerType } from '@29cm/contexts-banners-services';
import { getCampaignLink } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { bannerListImagePriorityMaxIndex } from '@29cm/contexts-home/constants';
import { Banner } from '@29cm/contexts-home/views';

interface BannerListProps {
  className?: string;
  banners: BannerType[];
}

export const BannerList = ({ className, banners }: BannerListProps) => {
  return (
    <ul className={className}>
      {banners.map((item, index) => {
        const href = getCampaignLink(item.linkType, item.linkValue);

        return (
          <Link key={item.bannerId} href={href}>
            <Banner
              className="aspect-[5/6]"
              banner={item}
              position={index + 1}
              priority={bannerListImagePriorityMaxIndex >= index}
            />
          </Link>
        );
      })}
    </ul>
  );
};
