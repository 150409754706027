'use client';

import { PropsWithChildren, useCallback, useRef } from 'react';
import { SubscriptionContext } from './SubscriptionContext';

export const SubscriptionProvider = ({ children }: PropsWithChildren) => {
  const subscribers = useRef<Set<() => void>>(new Set());

  const subscribe = useCallback((subscriber: () => void) => {
    subscribers.current.add(subscriber);

    return () => {
      subscribers.current.delete(subscriber);
    };
  }, []);

  const emit = useCallback(() => {
    subscribers.current.forEach((subscriber) => subscriber());
  }, []);

  return <SubscriptionContext.Provider value={{ subscribe, emit }}>{children}</SubscriptionContext.Provider>;
};
