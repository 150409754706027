import { GlobalCacheContext } from '@29cm/contexts-common-contexts';
import { useContext } from 'react';

export const useGlobalCacheContext = () => {
  const context = useContext(GlobalCacheContext);

  if (context === undefined) {
    throw new Error('useGlobalCacheContext 는 GlobalStateProvider 내에서 사용되어야 합니다.');
  }

  return context;
};
