export enum ImageType {
  // 대표 이미지
  MAIN = 3,
  // 부가 이미지 (화이트 색상)
  ADDITIONAL_WHITE = 2,
  // 부가 이미지 (그레이 색상)
  ADDITIONAL_GREY = 1,
  // 사은품 이미지
  GIFT = 9,
  // 추가 상품상세 - 기본 타입
  DETAIL_DEFAULT = 4,
  // 추가 상품상세 - 영상 타입
  DETAIL_MOVIE = 5,
  // 추가 상품상세 - 타이틀 구분 타입
  DETAIL_TITLE = 6,
  // 추가 상품상세 - 이미지 링크 타입
  DETAIL_LINK = 7,
}
