import { appEnv } from './appEnv';

export const brazeApiKey =
  appEnv === 'production'
    ? 'b9a58994-a795-4f4d-b145-1023fd6a1e11'
    : appEnv === 'qa'
      ? '7ad7b590-5b0a-4eb5-8df8-c374fb23d9f4'
      : 'cdfd9b11-9457-4aa8-a9fa-b1dc31fa4c8f';
export const brazeEndPoint =
  appEnv === 'production' ? 'sdk.iad-03.braze.com' : appEnv === 'qa' ? 'sdk.iad-03.braze.com' : 'sdk.iad-03.braze.com';

export const amplitudeApiKey =
  appEnv === 'production'
    ? '9591d6dba297fabb6457fa48ab12156c'
    : appEnv === 'qa'
      ? '929ca24bfc097c1caac43f13f7eaf6c8'
      : '929ca24bfc097c1caac43f13f7eaf6c8';
export const amplitudeExperimentServerKey =
  appEnv === 'production'
    ? 'server-t3GaSTS4QZyTmgjDQERzGUmIlwEt5wJa'
    : appEnv === 'qa'
      ? 'server-eQECJ68KjvnZASkCvdvy9PzbO0KKYUpw'
      : 'server-eQECJ68KjvnZASkCvdvy9PzbO0KKYUpw';
export const amplitudeExperimentClientKey =
  appEnv === 'production'
    ? 'client-CkSrf9T6MzqTEpjmtAhQS8j8iPmDGnHf'
    : appEnv === 'qa'
      ? 'client-NTBiInvoI22t1HFO35v296b5cqsU0gk1'
      : 'client-NTBiInvoI22t1HFO35v296b5cqsU0gk1';
export const amplitudeAnalyticsKey =
  appEnv === 'production'
    ? '9591d6dba297fabb6457fa48ab12156c'
    : appEnv === 'qa'
      ? 'ad2b2fa4f53f446559832081a7275198'
      : 'ad2b2fa4f53f446559832081a7275198';

export const unleashProxy =
  appEnv === 'production'
    ? 'https://flag.29cm.co.kr/proxy'
    : appEnv === 'qa'
      ? 'https://dev-flag.29cm.co.kr/proxy'
      : 'https://dev-flag.29cm.co.kr/proxy';
export const unleashClientSecret =
  appEnv === 'production'
    ? '3efc121a-0d46-4c9a-bdd1-a155c5488474'
    : appEnv === 'qa'
      ? '0f5e8ef7-f1ea-4bec-af65-abb5e1925bea'
      : '0f5e8ef7-f1ea-4bec-af65-abb5e1925bea';

export const appleClientId =
  appEnv === 'production' ? 'kr.co.29cm.signin' : appEnv === 'qa' ? 'kr.co.29cm.signin' : 'kr.co.29cm.signin';

export const facebookClientId =
  appEnv === 'production' ? '291704510930968' : appEnv === 'qa' ? '291704510930968' : '291704510930968';

export const naverClientId =
  appEnv === 'production' ? '3UNToo0c2vUrSFT0hBq3' : appEnv === 'qa' ? '_ci0xA93GJUKNvS52ryb' : 'z5B4l8eI_ABsSV2OGSGS';

export const kakaoClientId =
  appEnv === 'production'
    ? '0576138ae835b86d79d9ca905a58b03d'
    : appEnv === 'qa'
      ? '2ee9d9be5d84fbc184912ed61afdc34f'
      : '2ee9d9be5d84fbc184912ed61afdc34f';
export const kakaoApiKey =
  appEnv === 'production'
    ? '0576138ae835b86d79d9ca905a58b03d'
    : appEnv === 'qa'
      ? '63315ce505fc8fee26fb5da09738edfd'
      : '63315ce505fc8fee26fb5da09738edfd';

export const elasticsearchEndpoint =
  appEnv === 'production'
    ? 'https://web-log.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://dev-web-log.29cm.co.kr'
      : 'https://dev-web-log.29cm.co.kr';

export const shopliveAccessKey =
  appEnv === 'production' ? '5vFD93Q1U589HOm4qNJp' : appEnv === 'qa' ? '5vFD93Q1U589HOm4qNJp' : '5vFD93Q1U589HOm4qNJp';

export const snowplowView_item_category =
  appEnv === 'production'
    ? 'iglu:com.29cm/view_item_category/jsonschema/1-0-2'
    : appEnv === 'qa'
      ? 'iglu:com.29cm/view_item_category/jsonschema/1-0-2'
      : 'iglu:com.29cm/view_item_category/jsonschema/1-0-2';

export const appsflyerTemplateId =
  appEnv === 'production' ? '1080201211' : appEnv === 'qa' ? '1080201211' : '1080201211';
export const appsflyerApikey =
  appEnv === 'production'
    ? '1b3u1l4h0010O00002OsqbbQAB1s6h3a2t'
    : appEnv === 'qa'
      ? '1b3u1l4h0010O00002OsqbbQAB1s6h3a2t'
      : '1b3u1l4h0010O00002OsqbbQAB1s6h3a2t';
