'use client';

import { Feed, FeedProduct } from '@29cm/contexts-feeds-services';
import { Carousel, CarouselRef } from '@29cm/contexts-home/views';
import { useCallback, useRef } from 'react';
import { MultiProductCardCarouseItem } from './MultiProductCardCarouseItem';

interface Props {
  feed: Feed;
  items: FeedProduct[];
}

const itemWidth = { sm: '136px' };
const horizontalPadding = { sm: '20px' };

export const MultiProductCardCarousel = ({ feed, items }: Props) => {
  const cardCarouselRef = useRef<CarouselRef>(null);

  const renderItem = useCallback(
    (item: (typeof items)[number]) => {
      return <MultiProductCardCarouseItem product={item} feed={feed} />;
    },
    [feed],
  );

  return (
    <Carousel
      ref={cardCarouselRef}
      items={items}
      renderItem={renderItem}
      itemWidth={itemWidth}
      gap={6}
      horizontalPadding={horizontalPadding}
      snapAlign="start"
      alignItems="start"
    />
  );
};
