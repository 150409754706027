'use client';

import { createContext } from 'react';

type SubscriptionContextValue = {
  subscribe: (subscriber: () => void) => () => void;
  emit: () => void;
};

export const SubscriptionContext = createContext<SubscriptionContextValue | undefined>(undefined);
