import { useCallback } from 'react';
import { AnalyticsPlatforms } from '../interfaces';
import { useAnalyticsContext } from './useAnalyticsContext';

type EventTrackerOptions = {
  once?: boolean;
  platforms?: AnalyticsPlatforms;
};

/**
 * @example
 *
 * ```tsx
 * const track = useAnalytics();
 *
 * track('visit_home_main_page', {
 *   ...
 * });
 * ```
 */
export const useAnalytics = () => {
  const { trackState, track } = useAnalyticsContext();

  const { isTracked, setTracked } = trackState;

  // TODO: 이벤트 수집에 필요한 맥락 프로퍼티, options 를 사용처가 아닌 useAnalytics 내에서 주입할 수 있도록 처리
  return useCallback(
    (name: string, properties?: Record<PropertyKey, unknown>, options: EventTrackerOptions = {}) => {
      const { once = false, platforms } = options;

      if (once) {
        if (isTracked(name, properties)) {
          return;
        } else {
          setTracked(name, properties);
        }
      }

      track(name, properties, platforms);
    },
    [track, isTracked, setTracked],
  );
};
