import { isClient } from '@29cm/utils-contexts';
import { UtmParameterKeys } from '../constants';
import { UtmParameters } from '../interfaces';

/**
 * @returns
 * - utmSource - 매체
 * - utmCampaign - 캠페인
 * - utmContent - 광고 소재/제품/키워드
 */
export const getUtmParamsValue = (): Partial<UtmParameters> => {
  if (!isClient()) {
    return {};
  }
  const url = new URL(document.location.href);

  const [utmCampaign, utmContent, utmSource] = [
    UtmParameterKeys.UTM_CAMPAIGN,
    UtmParameterKeys.UTM_CONTENT,
    UtmParameterKeys.UTM_SOURCE,
  ].map((key) => url.searchParams.get(key)?.toString());

  return {
    utmCampaign: utmCampaign,
    utmContent: utmContent,
    utmSource: utmSource,
  };
};
