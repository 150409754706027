'use client';

import { useAnalytics } from '@29cm/contexts-analytics/hooks';
import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { getCampaignLink, isMdScreen } from '@29cm/contexts-common-utils';
import { ResponsiveImage } from '@29cm/contexts-common-views';
import { Feed } from '@29cm/contexts-feeds-services';
import Link from 'next/link';
import { PropsWithChildren, memo } from 'react';

interface Props {
  className?: string;
  feed: Feed;
}

export const ContentsFeedLayout = memo(({ className, children, feed }: PropsWithChildren<Props>) => {
  const track = useAnalytics();

  const { title, contents, linkType, linkValue, relatedFiles } = feed;

  const [thumbnail] = relatedFiles;

  const href = getCampaignLink(linkType, linkValue);

  const src = thumbnail?.uploadName ?? transparentFallbackImageSrc;

  // TODO: 이벤트명 한 파일에서 관리 (SSOT 준수)
  const handleClick = () => {
    // NOTE: 모바일에서만 해당 이벤트를 수집합니다.
    if (isMdScreen()) {
      return;
    }

    track(
      'click_feed',
      {
        feed_no: `${feed.id}`,
        feed_type: feed.type,
        feed_title: feed.title,
        feed_contents: feed.contents,
        link_type: feed.linkType,
        link_value: feed.linkValue,
        // NOTE: As-is 모바일 디바이스에서 position 을 항상 right 로 수집 (모바일은 list 가 세로 한 줄)
        position: 'right',
      },
      {
        platforms: { firebase: true },
      },
    );
  };

  return (
    <div className={className} onClick={handleClick}>
      <div className="flex flex-col gap-12">
        <Link href={href}>
          <div className="flex flex-col gap-12">
            <div className="relative aspect-square overflow-hidden rounded-2 bg-low">
              <ResponsiveImage
                className="object-cover"
                src={src}
                alt={title}
                fill
                sizes={{ sm: '100vw', md: '50vw' }}
              />
            </div>
            <div className="flex flex-col gap-10">
              <p className="text-title-m-bold">{title}</p>
              <p className="text-xl">{contents}</p>
            </div>
          </div>
        </Link>
        {children}
      </div>
    </div>
  );
});
