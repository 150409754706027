'use client';

import { useAnalytics } from '@29cm/contexts-analytics/hooks';
import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { getCampaignLink, getImageUrl, isMdScreen } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { Feed, FeedProduct } from '@29cm/contexts-feeds-services';
import { ProductCardWithHeart } from '@29cm/contexts-products-features';

interface Props {
  product: FeedProduct;
  feed: Feed;
}

export const MultiProductCardCarouseItem = ({ feed, product }: Props) => {
  const track = useAnalytics();

  const { images, frontBrand, name, sale, heartCount, reviewCount, id } = product;
  const [image] = images;

  const imageUrl = image !== undefined ? `${getImageUrl(image.uploadName)}?width=300` : transparentFallbackImageSrc;
  const href = getCampaignLink('product', id);

  const handleClick = () => {
    // NOTE: 모바일에서만 해당 이벤트를 수집합니다.
    if (isMdScreen()) {
      return;
    }

    track(
      'click_feed',
      {
        feed_no: `${feed.id}`,
        feed_type: feed.type,
        feed_title: feed.title,
        feed_contents: feed.contents,
        link_type: feed.linkType,
        link_value: feed.linkValue,
        // NOTE: As-is 모바일 디바이스에서 position 을 항상 right 로 수집 (모바일은 list 가 세로 한 줄)
        position: 'right',
        item_no: `${product.id}`,
        item_name: product.name,
        like_count: `${product.heartCount}`,
        price: `${product.sale.sellPrice}`,
        review_count: `${product.reviewCount}`,
      },
      {
        platforms: { firebase: true },
      },
    );
  };

  return (
    <Link href={href} onClick={handleClick}>
      <ProductCardWithHeart
        productId={id}
        imgUrl={imageUrl}
        productName={name}
        price={sale.totalSellPrice}
        discount={sale.totalSaleRate}
        brandName={frontBrand.nameKor}
        likeAmount={heartCount}
        reviewAmount={reviewCount}
      />
    </Link>
  );
};
