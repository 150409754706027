import { isServer } from '@29cm/utils-contexts';
import { isDevelopment } from '@29cm/utils-node';
import { querify } from '@29cm/utils-url';
import * as v from 'valibot';
import { AfParameterKeys, appsflyerOneLinkUrl } from '../constants';
import { AfSmartScriptInstance, AppsFlyerSmartScript, OneLinkContent, UtmParameters } from '../interfaces';
import { getCurrentLinkBasedAppLink } from './app-link-service';
import { getUtmParamsValue } from './utm-service';

type WindowWithAfSmartScript = Window & AfSmartScriptInstance;

const getAfSmartScriptInstance = (): AfSmartScriptInstance['AF_SMART_SCRIPT'] | undefined => {
  return (window as WindowWithAfSmartScript)?.['AF_SMART_SCRIPT'];
};

const isAfSmartScriptAvailable = () => {
  if (isServer() || getAfSmartScriptInstance() === undefined) {
    return false;
  }

  const validation = v.safeParse(AppsFlyerSmartScript, getAfSmartScriptInstance()).issues?.map((i) => i.message) ?? [];

  if (validation.length > 0) {
    const errorMessage = `window['AF_SMART_SCRIPT'] 파싱 값과 스키마가 일치하지 않습니다. ${validation.join('\n')}`;
    if (isDevelopment()) {
      throw Error(errorMessage);
    } else {
      console.error(errorMessage);
    }
  }

  return true;
};

/**
 * 29CM의 원링크 기본 path에 다음의 고정 파라미터와 매개변수로 받은 값을 조합하여 링크를 생성 합니다.
 * - `pid`: (고정값: 29cm_mowebtoapp) 앱스플라이어 연동 파트너를 고유하게 식별합니다.
 * - `is_retargeting`: (고정값: true) 모든 리타겟팅 캠페인의 클릭 URL은 &is_retargeting=true를 반드시 포함해야 합니다. 이 파라미터가 포함되지 않았거나 값이 "false"이면 캠페인은 일반 유저 유입 캠페인으로 간주됩니다.
 * - `click_lookback`: (고정값: 7d) 클릭 어트리뷰션을 위한 룩백 윈도우. 이 윈도우의 기간은 새로운 유저가 광고/링크가 게재된 미디어 소스에 어트리뷰션 될 수 있는 최대 CTIT(Click To Install Time, 클릭에서 앱 설치까지 소요시간)입니다.
 *
 * @param campaign 캠페인 이름, c 파라미터의 value.
 * @param afAdset 광고세트 이름, af_adset 파라미터의 value.
 * @param afAd 광고 이름, af_ad 파라미터의 value.
 * @link {https://support.appsflyer.com/hc/ko/articles/207447163-%EC%96%B4%ED%8A%B8%EB%A6%AC%EB%B7%B0%EC%85%98-%EB%A7%81%ED%81%AC-%EA%B5%AC%EC%A1%B0%EC%99%80-%ED%8C%8C%EB%9D%BC%EB%AF%B8%ED%84%B0}
 */
const generateCustomOneLinkUrl = ({ campaign, afAdset, afAd }: { campaign: string; afAdset: string; afAd: string }) => {
  const query = {
    pid: '29cm_mowebtoapp',
    c: campaign,
    af_adset: afAdset,
    af_ad: afAd,
    is_retargeting: 'true',
    af_click_lookback: '7d',
  } satisfies Record<
    | AfParameterKeys.PID
    | AfParameterKeys.C
    | AfParameterKeys.AF_ADSET
    | AfParameterKeys.AF_AD
    | AfParameterKeys.IS_RETARGETING
    | AfParameterKeys.AF_CLICK_LOOKBACK,
    string
  >;

  return `${appsflyerOneLinkUrl}${querify(query)}`;
};

/**
 * 원링크를 이미 타고 들어온 페이지에서 다시 원링크를 생성하는 경우 원링크에 사이드이펙트를 발생시킬 수 있는 쿼리스트링을 제거 합니다:
 * - `af_web_dp`: [리디렉션]데스크톱 사용자를 사용자 지정 웹 페이지로 리디렉션할 수 있습니다.
 * - `af_dp`: [리디렉션] iOS 및 안드로이드 앱을 실행하기 위한 폴백 방법으로 사용되는 URI 스키마입니다. 이는 유니버설 링크 또는 안드로이드 앱 링크 메소드가 실패하고 안드로이드 버전이 6.0 미만인 경우에 사용되도록 앱을 실행합니다. 여기에서 값을 설정하여 원링크 템플릿에서 URI 스키마 기본값을 재정의합니다(필요한 경우에만).
 * - `deep_link_value`: [딥링킹] 딥링킹과 디퍼드 딥링킹에 사용되는 앱링크입니다. 딥링킹은 앱 스토어나 앱 홈페이지로 먼저 연결되지 않고 기존 앱 사용자를 리타게팅하여 앱 내의 특정 경험(예: 특정 페이지)으로 직접 보내는 것을 의미합니다. 이러한 사용자는 리타게팅 어트리뷰션을 사용하여 어트리뷰션됩니다. 디퍼드 딥링킹은 신규 사용자에게 기존 사용자와 유사한 딥링킹 경험을 제공한다는 의미입니다. 그러나 사용자 흐름은 먼저 신규 사용자가 앱 스토어로 보내지고 앱을 내려받아 실행한 후 특정 경험이나 페이지로 보내지는 것입니다.
 */
const removeSideEffectQueryParams = (href: string) => {
  const url = new URL(href);
  url.searchParams.delete('af_dp');
  url.searchParams.delete('af_web_dp');
  url.searchParams.delete('deep_link_value');

  return url.href;
};

/**
 * 마케팅 어트리뷰션에 있어 mowebtoapp 배너가 웹 성과를 하이재킹하는 이슈를 해결하기 위해 스마트 스크립트로 원링크를 생성 합니다.
 * (e.g 페이스북 웹 랜딩 > mowebtoapp 배너 클릭 후 앱설치 > 정상: 페이스북으로 기여 / 하이재킹: mowebtoapp 배너로 기여)
 *
 * NOTE: 스마트 스크립트를 사용할 수 없는 환경에서는 generateCustomOneLinkUrl 로 생성한 조합형 원링크를 리턴 합니다.
 */
const generateOneLinkUrlWithSmartScript = ({
  utmCampaign,
  utmContent,
}: Pick<UtmParameters, 'utmCampaign' | 'utmContent'>): string => {
  const oneLinkURL = appsflyerOneLinkUrl;
  const webReferrer = 'af_sub5';
  const mediaSource = { keys: ['utm_source'], defaultValue: '29cm_mowebtoapp' };
  const campaign = { keys: ['utm_campaign'], defaultValue: 'install_mowebtoapp' };
  const channel = { keys: ['utm_medium'] };
  const ad = { keys: ['utm_content'], defaultValue: utmContent };
  const af_keyword = { paramKey: 'af_keyword', keys: ['utm_term'] };
  const adSet = { keys: ['utm_campaign'] };
  const is_retargeting = { paramKey: 'is_retargeting', defaultValue: 'true' };
  const af_click_lookback = { paramKey: 'af_click_lookback', defaultValue: '7d' };
  const custom_ss_ui = { paramKey: 'af_ss_ui', defaultValue: 'true' };

  if (!isAfSmartScriptAvailable()) {
    if (isDevelopment()) {
      throw Error('원링크 스마트 스크립트를 실행할 수 없습니다.');
    }

    return `${generateCustomOneLinkUrl({ campaign: campaign.defaultValue, afAdset: utmCampaign, afAd: utmContent })}&${AfParameterKeys.AF_SS_UI}=true`; // 스마트 스크립트 커스텀 파라미터가 붙은 조합형 원링크
  }

  return getAfSmartScriptInstance()!.generateOneLinkURL({
    oneLinkURL,
    webReferrer,
    afParameters: {
      mediaSource,
      channel,
      campaign,
      ad,
      afCustom: [af_keyword, is_retargeting, af_click_lookback, custom_ss_ui],
      adSet,
    },
  })!.clickURL;
};

/**
 * 현재 URL을 기반으로 딥링크 관련 파라미터 문자열을 생성합니다. 생성된 문자열은 다음과 같은 파라미터를 포함합니다:
 * - `af_web_dp`: [리디렉션]데스크톱 사용자를 사용자 지정 웹 페이지로 리디렉션할 수 있습니다.
 * - `af_dp`: [리디렉션] iOS 및 안드로이드 앱을 실행하기 위한 폴백 방법으로 사용되는 URI 스키마입니다. 이는 유니버설 링크 또는 안드로이드 앱 링크 메소드가 실패하고 안드로이드 버전이 6.0 미만인 경우에 사용되도록 앱을 실행합니다. 여기에서 값을 설정하여 원링크 템플릿에서 URI 스키마 기본값을 재정의합니다(필요한 경우에만).
 * - `deep_link_value`: [딥링킹] 딥링킹과 디퍼드 딥링킹에 사용되는 앱링크입니다. 딥링킹은 앱 스토어나 앱 홈페이지로 먼저 연결되지 않고 기존 앱 사용자를 리타게팅하여 앱 내의 특정 경험(예: 특정 페이지)으로 직접 보내는 것을 의미합니다. 이러한 사용자는 리타게팅 어트리뷰션을 사용하여 어트리뷰션됩니다. 디퍼드 딥링킹은 신규 사용자에게 기존 사용자와 유사한 딥링킹 경험을 제공한다는 의미입니다. 그러나 사용자 흐름은 먼저 신규 사용자가 앱 스토어로 보내지고 앱을 내려받아 실행한 후 특정 경험이나 페이지로 보내지는 것입니다.
 */
export const getCurrentLinkBasedDeepLinkParams = (initialSeparator: '?' | '&') => {
  if (isServer()) {
    return '';
  }

  const appLink = getCurrentLinkBasedAppLink();
  const webLink = removeSideEffectQueryParams(document.location.href);

  return `${initialSeparator}af_dp=${appLink}&deep_link_value=${appLink}&af_web_dp=${webLink}`;
};

type UseOneLinkParams = {
  legacy: Pick<UtmParameters, 'utmCampaign' | 'utmContent'>;
  defaultUtmContent: string;
  defaultUtmCampaign: string;
  type?: OneLinkContent;
};

export const generateOneLink = ({ legacy, defaultUtmContent, defaultUtmCampaign, type }: UseOneLinkParams): string => {
  const utmParams = getUtmParamsValue();
  const additionalDeepLinkParams = getCurrentLinkBasedDeepLinkParams('&');

  if (type !== undefined) {
    const link = generateOneLinkUrlWithSmartScript({
      utmCampaign: utmParams?.utmCampaign ?? defaultUtmCampaign,
      utmContent: utmParams?.utmContent ?? defaultUtmContent,
    });

    return `${link}${additionalDeepLinkParams}`;
  }

  // NOTE: 브랜드홈, TTRS 등 앱설치 배너, 바텀시트 외 앱 다운로드 구좌 에서는 조합형 원링크를 사용 합니다.
  return `${generateCustomOneLinkUrl({ campaign: utmParams?.utmSource ?? defaultUtmCampaign, afAdset: utmParams?.utmCampaign ?? legacy.utmCampaign, afAd: utmParams?.utmContent ?? legacy.utmContent })}${additionalDeepLinkParams}`;
};

export const generateAppInstallOneLink = (type: OneLinkContent): string => {
  return generateOneLink({
    legacy: {
      utmCampaign: 'mowebtoapp_top',
      utmContent: 'top_banner01',
    },
    defaultUtmContent: type === 'TOP_BANNER' ? 'top_banner' : 'app_install_layer',
    defaultUtmCampaign: 'install_mowebtoapp',
    type,
  });
};
