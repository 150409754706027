import * as v from 'valibot';
import { ApiResponse } from '../response-models';

export const wrapApiResponse = <T extends v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>>>(dataSchema: T) => {
  // TODO: variant 타입 사용해서 data nullable 처리하기
  return v.object({
    ...ApiResponse.entries,
    data: v.nullable(dataSchema),
  });
};
