'use client';

import { PropsWithChildren, createContext } from 'react';
import { useInitAnalytics } from '../../hooks/useInitAnalytics';
import { useTrackState } from '../../hooks/useTrackState';
import { Analytics, AnalyticsPlatforms, TrackState } from '../../interfaces';

type Props = {
  /**
   * @description 해당 페이지에서 사용할 애널리틱스의 service 객체들을 전달합니다.
   *
   * service 객체에는 함수가 포함되기 때문에 nextjs 의 app router 를 사용 중이라면 prop 을 전달하는 주체 컴포넌트가 client-component 여야 합니다.
   *
   * @example
   * ```tsx
   * 'use client';
   *
   * const Providers = ({ children }: PropsWithChildren) => {
   *   return (
   *     ...
   *     <AnalyticsProvider services={[firebase, amplitude, braze]}>
   *       {children}
   *     </AnalyticsProvider>
   *     ...
   *   )
   * };
   * ```
   */
  services: Analytics[];
};

export type AnalyticsContextValue = {
  trackState: TrackState;
  track: (name: string, properties?: Record<PropertyKey, unknown>, platforms?: AnalyticsPlatforms) => void;
};

export const AnalyticsContext = createContext<AnalyticsContextValue>({
  trackState: {
    isTracked: () => false,
    setTracked: () => {},
  },
  track: () => {},
});

export const AnalyticsProvider = ({ children, services }: PropsWithChildren<Props>) => {
  const trackState = useTrackState();
  const track = useInitAnalytics(services);

  return <AnalyticsContext.Provider value={{ trackState, track }}>{children}</AnalyticsContext.Provider>;
};
