'use client';

import { useAnalytics } from '@29cm/contexts-analytics/hooks';
import { Banner, Banners, fetchHomeMobileBannerList, fetchHomePcBannerList } from '@29cm/contexts-banners-services';
import { useFetch, useFetchInfinite, useIsMdScreen } from '@29cm/contexts-common-hooks';
import { Responsive } from '@29cm/contexts-common-views';
import { Feed, Feeds, HomeType, fetchFeeds } from '@29cm/contexts-feeds-services';
import { desktopBannerSize, desktopFeedSize, mobileBannerSize, mobileFeedSize } from '@29cm/contexts-home/constants';
import { Divider } from '@29cm/contexts-home/views';
import { Result } from '@29cm/contexts-http/interfaces';
import { getResultData } from '@29cm/contexts-http/utils';
import { useEffectOnce } from '@29cm/hooks-effects';
import { BannerSection } from '../BannerSection';
import { FeedSection } from '../FeedSection';
import { ReadMoreSection } from '../ReadMoreSection';

interface Props {
  mobileBanners: Result<Banners, Error>;
  desktopBanners: Result<Banners, Error>;
  mobileFeeds: Result<Feeds, Error>;
  desktopFeeds: Result<Feeds, Error>;
}

export const Home = ({ mobileBanners, desktopBanners, mobileFeeds, desktopFeeds }: Props) => {
  const { result: mobileBannerResult } = useFetch(
    fetchHomeMobileBannerList,
    { size: mobileBannerSize },
    { initialResult: mobileBanners },
  );

  const { results: desktopBannerResults, fetchNextPage: readMoreDesktopBanner } = useFetchInfinite(
    fetchHomePcBannerList,
    (pageParams?: { offset: number }) => ({
      offset: pageParams?.offset ?? 0,
      limit: desktopBannerSize,
    }),
    {
      initialResult: desktopBanners,
      getNextPageParams: getNextPageParamsBySize(desktopBannerSize),
    },
  );

  const { results: mobileFeedsResults, fetchNextPage: readMoreMobileFeeds } = useFetchInfinite(
    fetchFeeds,
    (pageParams?: { offset: number }) => ({
      offset: pageParams?.offset ?? 0,
      limit: mobileFeedSize,
      homeType: HomeType.M_WEB_HOME,
    }),
    {
      initialResult: mobileFeeds,
      getNextPageParams: getNextPageParamsBySize(mobileFeedSize),
    },
  );

  const { results: desktopFeedsResults, fetchNextPage: readMoreDesktopFeeds } = useFetchInfinite(
    fetchFeeds,
    (pageParams?: { offset: number }) => ({
      offset: pageParams?.offset ?? 0,
      limit: desktopFeedSize,
      homeType: HomeType.WEB_HOME,
    }),
    {
      initialResult: desktopFeeds,
      getNextPageParams: getNextPageParamsBySize(desktopFeedSize),
    },
  );

  const track = useAnalytics();

  useEffectOnce(() => {
    // TODO: 이벤트명 한 파일에서 관리 (SSOT 준수)
    track('visit_home_main_page', undefined, {
      once: true,
      platforms: { firebase: true },
    });
  });

  const mobileBannerList = getResultData(mobileBannerResult)?.results ?? [];
  const desktopBannerList = flattenResults(desktopBannerResults, [] as Banner[]);
  const mobileFeedList = flattenResults(mobileFeedsResults, [] as Feed[]);
  const desktopFeedList = flattenResults(desktopFeedsResults, [] as Feed[]);

  const isDesktop = useIsMdScreen();

  const readMoreBannerFeedCollection = () => {
    if (isDesktop) {
      readMoreDesktopBanner();
      readMoreDesktopFeeds();
      return;
    }

    readMoreMobileFeeds();
  };

  return (
    <div className="flex flex-col">
      <div className="relative flex flex-col md:flex-row">
        <Responsive className="absolute bottom-0 left-[44%] top-0 z-[1] h-full" md={<Divider direction="vertical" />} />
        <Responsive
          className="absolute bottom-0 right-[28%] top-0 z-[1] h-full"
          md={<Divider direction="vertical" />}
        />

        <BannerSection
          className="p-0 md:w-[44%] md:pr-[2%]"
          mobileBanners={mobileBannerList}
          desktopBanners={desktopBannerList}
        />
        <FeedSection className="md:w-[56%]" mobileFeeds={mobileFeedList} desktopFeeds={desktopFeedList} />
      </div>

      <ReadMoreSection onView={readMoreBannerFeedCollection} />
    </div>
  );
};

const getNextPageParamsBySize =
  (size: number) =>
  <T extends { next: string | null }, E extends Error>(incoming: Result<T, E>, existing: Result<T, E>[]) => {
    if (incoming.success && !incoming.data.next) {
      return;
    }

    return {
      offset: existing.length * size,
    };
  };

const flattenResults = <T extends { results: D[] }, E extends Error, D>(results: Result<T, E>[], initialValue: D[]) =>
  results.reduce((prev, curr) => {
    if (curr.success) {
      prev.push(...curr.data.results);
    }

    return prev;
  }, initialValue);
