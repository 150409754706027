import { forwardRef, memo, useCallback } from 'react';
import { tv } from 'tailwind-variants';
import { Preview } from './CardPreview';
import { ProductCardProps } from './ProductCard.types';
import ProductCardInfo from './ProductCardInfo';

export const productCard = tv({
  variants: {
    size: {
      small: 'mb-20 space-y-12',
      medium: 'mb-40 space-y-12',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

const BaseProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  ({ imgUrl, onHeart, onHeartClick, onClick, size, extraBadge, className, ...props }, ref) => {
    const handleClick = useCallback(() => onClick?.(), [onClick]);

    return (
      <div className={productCard({ size, className })} onClick={handleClick} ref={ref}>
        <Preview imgUrl={imgUrl} onHeart={onHeart} onHeartClick={onHeartClick} extraBadge={extraBadge} />
        <ProductCardInfo size={size} {...props} />
      </div>
    );
  },
);

export const ProductCard = memo(BaseProductCard);
