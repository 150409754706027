import { Feed } from '@29cm/contexts-feeds-services';
import { ReactNode } from 'react';

type FeedListRenderItems = ({ data }: { data: Feed }) => ReactNode;

interface FeedListProps {
  className?: string;
  items: Feed[];
  renderItem: FeedListRenderItems;
}

export const FeedList = ({ className, items, renderItem }: FeedListProps) => {
  return (
    <ul className={className}>
      {items.map((data) => (
        <li key={data.id}>{renderItem({ data })}</li>
      ))}
    </ul>
  );
};
