import { useMediaQuery } from '@29cm/hooks-medias';

/**
 * md 사이즈 스크린인지 감지하는 훅 입니다.
 * data fetching 과 같이 css 로 대응할 수 없는 스크린 사이즈 분기 처리에 사용 합니다.
 * @param {boolean} [initial=false] 초기 상태 값으로 기본값은 `false`입니다.
 * @returns {boolean}
 */
export const useIsMdScreen = (initial = false): boolean => {
  return useMediaQuery('(min-width: 541px)', initial);
};
