'use client';

import { PropsWithChildren } from 'react';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { UserContext } from './UserContext';

export const UserProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { currentUser, clear } = useCurrentUser();

  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        clear,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
