import { appEnv } from './appEnv';

export const inhouseMotherAdminPath =
  appEnv === 'production'
    ? 'https://mother.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://mother-qa.29cm.co.kr'
      : 'https://mother-dev.29cm.co.kr';
export const partnerMotherAdminPath =
  appEnv === 'production'
    ? 'https://partner.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://partner-qa.29cm.co.kr'
      : 'https://partner-dev.29cm.co.kr';
export const cmsAdminPath =
  appEnv === 'production'
    ? 'https://cms.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-cms.29cm.co.kr'
      : 'https://dev-cms.29cm.co.kr';
