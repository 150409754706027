import { FetcherKey } from '@29cm/contexts-common-constants';
import { isDevelopment } from '@29cm/utils-node';
import { Fetcher, HttpMiddleware, HttpMiddlewareParams, Result } from '../interfaces';

type CacheOptions = {
  /**
   * @description 캐시 정책 (fetch 함수에서 제공하는 기본 cache 정책 옵션)
   * @link { https://nextjs.org/docs/app/building-your-application/data-fetching/fetching-caching-and-revalidating#caching-data }
   */
  policy?: RequestCache;

  /**
   * @description time-based 방식의 캐시 재검증을 위한 초 단위 시간 또는 false 로 재검증 비활성화 (nextjs fetch 함수에서 제공하는 next.revalidate 옵션)
   * @link { https://nextjs.org/docs/app/building-your-application/data-fetching/fetching-caching-and-revalidating#revalidating-data }
   */
  revalidate?: number | false;

  /**
   * @description on-demand 방식의 캐시 재검증을 위한 식별 가능한 string 키 배열 (nextjs fetch 함수에서 제공하는 next.tags 옵션)
   * @link { https://nextjs.org/docs/app/building-your-application/data-fetching/fetching-caching-and-revalidating#on-demand-revalidation }
   */
  tags?: string[];
};

// NOTE: RequestInit 의 cache 옵션을 별도로 정의한 cache 옵션 객체 내에서 전달하기 위해 제외시킵니다.
type RequestOptions = Omit<RequestInit, 'cache'>;

type FetcherOptions<O, R, D = R> = {
  key: FetcherKey;
  method: Required<RequestOptions['method']>;
  url: string | ((options: O) => string);
  requestOption?: RequestOptions | ((options: O) => RequestOptions);
  strict?: boolean;
  validator: (data: unknown) => string[] | undefined;
  transformer?: (data: R) => D;
  middlewares?: HttpMiddleware[];
  cache?: CacheOptions;
};

export async function fetchAsResult<T>(url: string, requestOptions?: RequestInit): Promise<Result<T, Error>> {
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      // TODO: 서버 에러 핸들링

      // FIXME: 204 NoContent 대응
      return {
        success: true,
        data: await response.text().then((text) => {
          return text ? JSON.parse(text) : {};
        }),
      };
    } else {
      return {
        success: false,
        error: new Error(response.statusText),
      };
    }
  } catch (e) {
    return {
      success: false,
      error: e as Error,
    };
  }
}

export const createFetcher = <Options, ResponseType, DataType = ResponseType>({
  key,
  url: passedUrl,
  requestOption: passedRequestOption,
  strict = false,
  validator,
  transformer = (data) => data as unknown as DataType,
  middlewares = [],
  cache,
}: FetcherOptions<Options, ResponseType, DataType>): Fetcher<Options, DataType, Error> => {
  const fetcher = async (options: Options): Promise<Result<DataType, Error>> => {
    const url = typeof passedUrl === 'function' ? passedUrl(options) : passedUrl;
    const requestOptions =
      typeof passedRequestOption === 'function' ? passedRequestOption(options) : passedRequestOption;

    const { url: finalUrl, requestOptions: finalRequestOptions } = middlewares.reduce<HttpMiddlewareParams>(
      (result, middleware) => middleware(result),
      {
        url,
        requestOptions,
      },
    );

    const response = await fetchAsResult<ResponseType>(finalUrl, {
      ...finalRequestOptions,
      cache: cache?.policy,
      next: {
        revalidate: cache?.revalidate,
        tags: cache?.tags,
      },
    });

    if (!response.success) {
      return response;
    }

    const responseData = response.data;
    const validation = validator(responseData) ?? [];
    if (validation.length > 0) {
      const errorMessage = `서버 응답과 스키마가 일치하지 않습니다. ${validation.join('\n')}`;
      if (strict || isDevelopment()) {
        throw new Error(errorMessage);
      } else {
        console.error(errorMessage);
      }
    }

    const transformedData = transformer(responseData);
    return {
      success: true,
      data: transformedData,
    };
  };

  fetcher.key = key;

  return fetcher;
};
