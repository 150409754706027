import { createCacheStore } from '@29cm/contexts-common-stores';
import { useCallback, useEffect, useMemo, useSyncExternalStore } from 'react';
import { useGlobalCacheContext } from '../global-cache/useGlobalCacheContext';

type UseStoreOptions<T> = {
  ttl: number;
  initialValue: T;
};

type UseStoreReturns<T> = [T | undefined, (incoming: T) => void];

export const useCacheStore = <T>(key: string, options: UseStoreOptions<T>): UseStoreReturns<T> => {
  const { globalCacheRegistry } = useGlobalCacheContext();

  const store = useMemo(() => createCacheStore<T>(key, globalCacheRegistry), [key, globalCacheRegistry]);

  const { ttl, initialValue } = options;

  const getSnapshot = useCallback(() => store.get(), [store]);

  const getServerSnapshot = useCallback(() => {
    // NOTE: server side 에서 해당 함수가 실행되면, initialValue 를 캐시해 둡니다.
    store.set(initialValue, ttl);

    return initialValue;
  }, [store, initialValue, ttl]);

  const snapshot = useSyncExternalStore(store.subscribe, getSnapshot, getServerSnapshot);

  useEffect(
    // NOTE: store 가 업데이트되면 메모리 관리를 위해 이전 캐시가 stale 한지 검증 후 제거합니다.
    () => () => {
      if (store.isStale()) {
        store.remove();
      }
    },
    [store],
  );

  const set = useCallback((incoming: T) => store.set(incoming, ttl), [store, ttl]);

  return [snapshot, set];
};
