import { contentAppPath, productAppPath, shopAppPath } from '@29cm/contexts-common-constants';
import { urlJoin } from '@29cm/utils-url';

type LinkType = 'product' | 'brand' | 'post';

type ProtocolLinkType = 'web' | 'app' | 'coupon';

const linkTypeMap = {
  product: `${productAppPath}/catalog/`,
  brand: `${shopAppPath}/brand/`,
  post: `${contentAppPath}/post`,
} as const;

/**
 * 배너,피드와 같이 api 응답에 linkType, linkValue 가 다변적으로 내려오는 경우, 해당 유틸을 사용하여 약속된 링크를 생성 합니다.
 * @param {string | LinkType | ProtocolLinkType} type - linkType ('web', 'app', 'coupon', 'product', 'brand', 'post')
 * @param {string | number} value - linkValue
 * @returns {string}
 */
export const getCampaignLink = (type: string | LinkType | ProtocolLinkType, value: string | number): string => {
  if (type === 'web') {
    const regex = new RegExp(/^(ftp|http|https|app29cm):\/\//);
    return regex.test(value.toString()) ? value.toString() : `//${value}`;
  }

  if (type === 'app' || type === 'coupon') {
    return `${value}`;
  }

  if (type in linkTypeMap) {
    return urlJoin(linkTypeMap[type as LinkType], `${value}`);
  }

  return `${value}`;
};
