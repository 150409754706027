import { decodeBase64 } from '@29cm/utils-buffer';
import { User } from '../interfaces';

/**
 * cookie에서 유저 관련 value 는 base64로 인코딩되어 있으므로 디코딩하는 유틸 함수 입니다.
 */
export const decodeUser = (encodedUser: Record<keyof User, string | undefined>): User => {
  const { id, no, creatorNo, name, email, grade, gradeName, gender, accessToken, refreshToken } = encodedUser;

  return {
    id: id !== undefined ? decodeBase64(id) : '',
    no: no !== undefined ? decodeBase64(no) : '',
    creatorNo: creatorNo !== undefined ? decodeBase64(creatorNo) : undefined,
    name: name !== undefined ? decodeBase64(name) : '',
    email: email !== undefined ? decodeBase64(email) : '',
    grade:
      grade !== undefined && !Number.isNaN(Number(decodeBase64(grade.toString())))
        ? Number(decodeBase64(grade.toString()))
        : undefined,
    gradeName: gradeName !== undefined ? decodeBase64(gradeName) : '',
    gender: gender !== undefined ? (decodeBase64(gender) as User['gender']) : undefined,
    accessToken: accessToken !== undefined ? decodeBase64(accessToken) : '',
    refreshToken: refreshToken !== undefined ? decodeBase64(refreshToken) : '',
  };
};
