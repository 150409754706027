'use client';

import { PropsWithChildren, useRef } from 'react';
import { CacheRegistry } from '../interfaces';
import { GlobalCacheContext } from './GlobalCacheContext';

export const GlobalCacheProvider = ({ children }: PropsWithChildren) => {
  const value = useRef({
    globalCacheRegistry: new Map<string, CacheRegistry>(),
    promiseMap: new Map<string, Promise<unknown>>(),
  });

  return <GlobalCacheContext.Provider value={value.current}>{children}</GlobalCacheContext.Provider>;
};
