/**
 * 로깅 및 useCachedFetch 사용 시 cache key 충돌 방지 목적의 enum
 *
 * enum value는 string 타입이어야 합니다.
 */
export const enum FetcherKey {
  FetchAutoCompleteSearchKeywords = 'fetchAutoCompleteSearchKeywords',
  FetchFeeds = 'fetchFeeds',
  FetchGroupCategoryById = 'fetchGroupCategoryById',
  FetchHomeMobileBannerList = 'fetchHomeMobileBannerList',
  FetchHomePcBannerList = 'fetchHomePcBannerList',
  FetchLogin = 'fetchLogin',
  FetchLogout = 'fetchLogout',
  FetchNoticeList = 'fetchNoticeList',
  FetchPopularSearchKeywords = 'fetchPopularSearchKeywords',
  FetchSetHeart = 'fetchSetHeart',
  FetchSingleCategoryById = 'fetchSingleCategoryById',
  FetchSocialLogin = 'fetchSocialLogin',
  FetchUnsetHeart = 'fetchUnsetHeart',
  FetchUser = 'fetchUser',
  FetchUserHeartList = 'fetchUserHeartList',
  FetchUnleashFeatureFlag = 'fetchUnleashFeatureFlag',
}
