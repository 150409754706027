import { Banner } from '@29cm/contexts-banners-services';
import { Responsive } from '@29cm/contexts-common-views';
import { BannerCarousel } from '../BannerCarousel';
import { BannerList } from '../BannerList';

interface Props {
  className?: string;
  mobileBanners: Banner[];
  desktopBanners: Banner[];
}

export const BannerSection = ({ className, mobileBanners, desktopBanners }: Props) => {
  return (
    <Responsive
      className={className}
      sm={<BannerCarousel banners={mobileBanners} />}
      md={<BannerList banners={desktopBanners} />}
    />
  );
};
