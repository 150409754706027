import { add as dateFnsAdd } from 'date-fns/add';

/**
 * 주어진 날짜에 연도, 월, 주, 일, 시간, 분, 초를 추가하는 함수
 * @example
 * add(new Date(2024, 7, 7), { days: 1 } //=> Wed Aug 08 2024 00:00:00 GMT+0900 (한국 표준시)
 * add(new Date(2024, 7, 7), { years: 1 } //=> Wed Aug 08 2025 00:00:00 GMT+0900 (한국 표준시)
 */
export const add = <DateType extends Date>(
  date: string | number | DateType,
  duration: {
    years?: number;
    months?: number;
    weeks?: number;
    days?: number;
    seconds?: number;
  },
) => {
  return dateFnsAdd(date, duration);
};
