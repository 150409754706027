import { MouseEvent, useCallback, useMemo } from 'react';
import { tv } from 'tailwind-variants';
import { EmoIcon as Icon } from '../EmoIcon';
import { BrandCardProps } from './BrandCard.types';

type BrandCardActionProps = Pick<BrandCardProps, 'variant' | 'size' | 'onHeart' | 'likeCount' | 'onHeartClick'>;
const brandCardAction = tv({
  slots: {
    base: 'flex h-full flex-col items-center justify-center',
  },
  variants: {
    variant: {
      default: '',
      like: '',
    },
    size: {
      small: '',
      medium: '',
    },
  },
  compoundSlots: [
    // base (by variant, size)
    { slots: ['base'], variant: 'default', size: 'small', class: 'w-[16px]' },
    { slots: ['base'], variant: 'default', size: 'medium', class: 'w-[20px]' },
    { slots: ['base'], variant: 'like', size: 'small', class: 'w-[36px]' },
    { slots: ['base'], variant: 'like', size: 'medium', class: 'w-[52px]' },
  ],
});

function BrandCardAction({ variant, size, likeCount, onHeart, onHeartClick }: BrandCardActionProps) {
  const style = brandCardAction({ size, variant });
  const formattedLikeCount = useMemo(
    () => (likeCount ? Intl.NumberFormat('en', { notation: 'compact' }).format(likeCount) : likeCount),
    [likeCount],
  );

  const handleHeartClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onHeartClick?.();
    },
    [onHeartClick],
  );

  return (
    <>
      {variant === 'like' ? (
        <div className={style.base()} onClick={handleHeartClick} role="button">
          <Icon
            type="heart"
            weight="regular"
            fill={onHeart}
            size={size === 'small' ? 20 : 24}
            color={onHeart ? 'accent' : size === 'small' ? 'primary' : 'tertiary'}
          />
          {size === 'medium' && <span className="text-s-medium text-tertiary">{formattedLikeCount}</span>}
        </div>
      ) : (
        <div className={style.base()}>
          <Icon type="chevronRight" color="tertiary" size={size === 'small' ? 16 : 20} weight="regular" />
        </div>
      )}
    </>
  );
}

export default BrandCardAction;
