import { createPromiseStore } from '@29cm/contexts-common-stores';
import { Fetcher, Result } from '@29cm/contexts-http/interfaces';
import { useEffectOnce, useUpdateEffect } from '@29cm/hooks-effects';
import { useCallback, useMemo, useState } from 'react';
import { FetchStatus } from '../interfaces';

type UseFetchInfiniteOptions<T, P, E extends Error> = {
  initialResult?: Result<T, E>;
  getNextPageParams?: (incoming: Result<T, E>, existing: Result<T, E>[]) => P | undefined;
};

type FetchInfiniteStatus = FetchStatus | 'fetching-next-page';

export const useFetchInfinite = <P, O = unknown, T = unknown, E extends Error = Error>(
  fetcher: Fetcher<O, T, E>,
  getFetcherOptions: (pageParams?: P) => O,
  options: UseFetchInfiniteOptions<T, P, E> = {},
) => {
  const { initialResult, getNextPageParams } = options ?? {};

  const hasInitialResult = initialResult !== undefined;

  const initialStatus = hasInitialResult ? 'done' : 'pending';
  const initialResults = hasInitialResult ? [initialResult] : [];
  const initialPageParams = hasInitialResult ? getNextPageParams?.(initialResult, [initialResult]) : undefined;

  const [status, setStatus] = useState<FetchInfiniteStatus>(initialStatus);
  const [lastResult, setLastResult] = useState<Result<T, E> | undefined>(initialResult);
  const [results, setResults] = useState<Result<T, E>[]>(initialResults);
  const [pageParams, setPageParams] = useState<P | undefined>(initialPageParams);

  // NOTE: 참조 타입일 수 있는 fetcherOptions 를 원시 타입으로 변환하여 변경을 감지할 수 있도록 합니다.
  const key = useMemo(() => JSON.stringify(getFetcherOptions()), [getFetcherOptions]);

  const { batch } = useMemo(() => createPromiseStore(key), [key]);

  const fetch = useCallback(
    async (prevResults: Result<T, E>[], prevPageParams?: P) => {
      const incoming = await fetcher(getFetcherOptions(prevPageParams));

      setStatus('done');
      setLastResult(incoming);

      if (!incoming.success) {
        return;
      }

      const existing = [...prevResults, incoming];

      setPageParams(getNextPageParams?.(incoming, existing));
      setResults(existing);
    },
    [fetcher, getFetcherOptions, getNextPageParams],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const batchedFetch = useCallback(batch(fetch), [fetch]);

  // TODO: refetch 실행 전, 진행 중인 요청 취소 처리
  const refetch = useCallback(async () => {
    setStatus('refetching');
    fetch([]);
  }, [fetch]);

  const fetchNextPage = useCallback(async () => {
    if (pageParams === undefined) {
      return;
    }

    setStatus('fetching-next-page');
    fetch(results, pageParams);
  }, [results, pageParams, fetch]);

  useEffectOnce(() => {
    if (hasInitialResult) {
      return;
    }

    setStatus('pending');
    batchedFetch(results, pageParams);
  });

  useUpdateEffect(() => {
    setStatus('pending');
    batchedFetch([]);
    // NOTE: key 가 변경된 시점에만 effect 를 실행합니다.
  }, [key]);

  const hasNextPage = pageParams !== undefined;

  return {
    status,
    hasNextPage,
    results,
    lastResult,
    refetch,
    fetchNextPage,
  };
};
