import { useContext } from 'react';
import { CookieContext } from '../features';

export const useCookieContext = () => {
  const context = useContext(CookieContext);

  if (!context) {
    throw new Error('CookieContext should be used within Root');
  }

  return context;
};
