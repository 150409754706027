import { isMediaMatches } from '@29cm/utils-medias';

/**
 * md 사이즈 스크린인지 감지하는 훅 입니다.
 * data fetching 과 같이 css 로 대응할 수 없는 스크린 사이즈 분기 처리에 사용 합니다.
 * @returns {boolean}
 */
export const isMdScreen = (): boolean => {
  return isMediaMatches('(min-width: 541px)');
};
