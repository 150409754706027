'use client';

import { useAnalytics } from '@29cm/contexts-analytics/hooks';
import { Intersection } from '@29cm/contexts-common-features';
import { useBodyScrollLock, useIsSmScreen } from '@29cm/contexts-common-hooks';
import { useCloseAppInstallBottomSheetFor24HoursStore } from '@29cm/contexts-persistent-state/hooks';
import { generateAppInstallOneLink } from '@29cm/contexts-smart-link/services';
import { Button, TextButton } from '@29cm/ruler';

export const HomeMainAppInstallLayer = () => {
  const isSmScreen = useIsSmScreen();

  const { closeFor24Hours, setCloseFor24Hours } = useCloseAppInstallBottomSheetFor24HoursStore();

  const shouldShowAppInstallLayer = isSmScreen && !closeFor24Hours;

  useBodyScrollLock(shouldShowAppInstallLayer);

  const track = useAnalytics();

  if (!shouldShowAppInstallLayer) {
    return null;
  }

  // TODO: 이벤트명 한 파일에서 관리 (SSOT 준수)
  const trackView = () =>
    track(
      'view_coupon_trigger',
      {
        curr_screen: 'home_main',
      },
      { platforms: { firebase: true } },
    );

  // TODO: 이벤트명 한 파일에서 관리 (SSOT 준수)
  const trackClick = () =>
    track(
      'click_coupon_trigger',
      {
        curr_screen: 'home_main',
      },
      { platforms: { firebase: true } },
    );

  const handleAppInstallButtonClick = () => {
    trackClick();
    setCloseFor24Hours();
    document.location.href = generateAppInstallOneLink('INSTALL_LAYER');
  };

  const handleClose = () => {
    setCloseFor24Hours();
  };

  return (
    <Intersection onView={trackView}>
      <div className="fixed inset-0 z-popup flex items-end justify-stretch bg-[#00000080] md:hidden">
        <div className="pb-safe flex flex-1 flex-col items-stretch rounded-t-20 bg-on-white px-20 pt-30">
          <p className="px-10 text-start text-title-l-bold text-primary">
            지금 앱을 설치하면
            <br />
            2장의 쿠폰을 선물하고 있어요
          </p>

          <div className="mx-auto mb-[19px] mt-[14px] h-[98px] w-[201px]">
            <img
              src="https://img.29cm.co.kr/next-edition/2023/06/01/1dc47432ac5a4f16952e1996dd37a925_20230601202920.png?width=402"
              className="h-full object-cover"
            />
          </div>

          <div className="mb-20 flex flex-col gap-8">
            <Button priority="primary" size="small" stretch onClick={handleAppInstallButtonClick}>
              첫구매용 앱 쿠폰받기
            </Button>

            <TextButton priority="tertiary" size="small" className="[&>span]:text-s" bold={false} onClick={handleClose}>
              오늘 하루 보지 않기
            </TextButton>
          </div>
        </div>
      </div>
    </Intersection>
  );
};
