import { appEnv } from './appEnv';

// TODO: 이제 사용되지 않는 path 찾아서 제거하기

export const homeLegacyAppPath =
  appEnv === 'production'
    ? 'https://www.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://nqa.29cm.co.kr'
      : 'https://ndev.29cm.co.kr';
export const authLegacyAppPath =
  appEnv === 'production'
    ? 'https://www.29cm.co.kr/member'
    : appEnv === 'qa'
      ? 'https://nqa.29cm.co.kr/member'
      : 'https://ndev.29cm.co.kr/member';
export const shopLegacyAppPath =
  appEnv === 'production'
    ? 'https://www.29cm.co.kr/shop/'
    : appEnv === 'qa'
      ? 'https://nqa.29cm.co.kr/shop/'
      : 'https://ndev.29cm.co.kr/shop/';
export const orderLegacyAppPath =
  appEnv === 'production'
    ? 'https://www.29cm.co.kr/order/'
    : appEnv === 'qa'
      ? 'https://nqa.29cm.co.kr/order/'
      : 'https://ndev.29cm.co.kr/order/';
export const mediaLegacyAppPath =
  appEnv === 'production'
    ? 'https://www.29cm.co.kr/media/'
    : appEnv === 'qa'
      ? 'https://nqa.29cm.co.kr/media/'
      : 'https://ndev.29cm.co.kr/media/';
export const productLegacyAppPath =
  appEnv === 'production'
    ? 'https://www.29cm.co.kr/product/'
    : appEnv === 'qa'
      ? 'https://nqa.29cm.co.kr/product/'
      : 'https://ndev.29cm.co.kr/product/';
export const postLegacyAppPath =
  appEnv === 'production'
    ? 'https://post.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://post-qa.29cm.co.kr'
      : 'https://post-dev.29cm.co.kr';
export const eventLegacyAppPath =
  appEnv === 'production'
    ? 'https://www.29cm.co.kr/event/'
    : appEnv === 'qa'
      ? 'https://nqa.29cm.co.kr/event/'
      : 'https://ndev.29cm.co.kr/event/';
export const searchLegacyAppPath =
  appEnv === 'production'
    ? 'https://search.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://search-qa.29cm.co.kr'
      : 'https://search-dev.29cm.co.kr';
export const mypageLegacyAppPath =
  appEnv === 'production'
    ? 'https://www.29cm.co.kr/mypage/'
    : appEnv === 'qa'
      ? 'https://nqa.29cm.co.kr/mypage/'
      : 'https://ndev.29cm.co.kr/mypage/';
export const recruitLegacyAppPath =
  appEnv === 'production'
    ? 'https://www.29cm.co.kr/mypage/recruit/'
    : appEnv === 'qa'
      ? 'https://nqa.29cm.co.kr/mypage/recruit/'
      : 'https://ndev.29cm.co.kr/mypage/recruit/';
export const customerLegacyAppPath =
  appEnv === 'production'
    ? 'https://www.29cm.co.kr/mypage/cscenter'
    : appEnv === 'qa'
      ? 'https://nqa.29cm.co.kr/mypage/cscenter'
      : 'https://ndev.29cm.co.kr/mypage/cscenter';
