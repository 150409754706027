import { useCookieStore } from '@29cm/contexts-cookie/hooks';
import { add } from '@29cm/utils-dates';

const CLOSE_APP_INSTALL_BOTTOM_SHEET_KEY = 'layer_app_install';
const CLOSE_APP_INSTALL_BOTTOM_SHEET_VALUE = 'closed';

export const useCloseAppInstallBottomSheetFor24HoursStore = () => {
  const {
    cookies: [closeState],
    setCookies,
  } = useCookieStore([CLOSE_APP_INSTALL_BOTTOM_SHEET_KEY]);

  const setCloseFor24Hours = () => {
    setCookies({
      [CLOSE_APP_INSTALL_BOTTOM_SHEET_KEY]: {
        value: CLOSE_APP_INSTALL_BOTTOM_SHEET_VALUE,
        options: {
          end: add(new Date(), { days: 1 }),
          domain: '.29cm.co.kr',
          path: '/',
        },
      },
    });
  };

  return {
    closeFor24Hours: closeState === CLOSE_APP_INSTALL_BOTTOM_SHEET_VALUE,
    setCloseFor24Hours,
  };
};
