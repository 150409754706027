import { commaizeNumber } from '@29cm/contexts-common-utils';
import { Badge, Icon } from '@29cm/ruler';
import { memo, useMemo } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';
import { EmoTextButton } from '../EmoTextButton';

const productCardInfo = tv(
  {
    slots: {
      base: 'space-y-12',
      header: 'space-y-6',
      requiredInfo: 'mr-10 space-y-2',
      brandName: 'mb-2 text-xs-bold',
      productName: 'mb-6 text-s',
      priceInfo: 'flex items-center gap-2',
      discount: 'text-l-bold text-accent',
      metaDataGroup: 'text-tertiary',
      metaData: 'flex flex-wrap gap-2',
      amountText: 'text-tertiary',
    },
    variants: {
      productNameLineClamp: {
        1: {
          productName: 'line-clamp-1',
        },
        2: {
          productName: 'line-clamp-2',
        },
      },
      hasPadding: {
        true: 'mx-10',
      },
      size: {
        small: {
          base: 'space-y-8',
          header: 'space-y-6',
          requiredInfo: 'mr-20 space-y-2',
          brandName: 'mb-2 text-xs-bold',
          productName: 'mb-2 text-s',
          priceInfo: 'flex gap-2 text-l-bold',
          metaDataGroup: 'space-y-12',
          amountText: 'text-xxs-medium',
        },
        medium: {
          base: 'space-y-10',
          requiredInfo: 'mr-20 space-y-2',
          brandName: 'mb-2 text-m-bold',
          productName: 'mb-6 text-l',
          priceInfo: 'flex gap-2 text-xxl-bold',
          metaDataGroup: 'space-y-[14px]',
          amountText: 'text-xs-medium',
        },
      },
      soldOut: {
        true: {
          discount: 'text-disabled',
          priceInfo: 'text-disabled',
        },
      },
    },
    defaultVariants: {
      size: 'small',
      soldOut: false,
    },
  },
  {
    twMerge: false,
  },
);

type ProductCardInfoVariants = VariantProps<typeof productCardInfo>;

type RequiredInfo = {
  productName: string;
  price: number;
};

type MetaData = {
  likeAmount: number;
  reviewAmount: number;
};

type Badge = {
  name: string;
};
type OptionalInfo = Partial<
  MetaData & {
    brandName: string;
    onClickBrandName: React.MouseEventHandler<HTMLButtonElement>;
    discount: number;
    badges: Badge[];
    soldOut?: boolean;
  }
>;

export interface ProductInfoProps extends RequiredInfo, ProductCardInfoVariants, OptionalInfo {}

const ProductCardInfo = ({
  discount,
  price,
  brandName,
  onClickBrandName,
  size = 'small',
  productName,
  likeAmount,
  reviewAmount,
  badges,
  hasPadding,
  productNameLineClamp = 1,
  soldOut = false,
}: ProductInfoProps) => {
  const info = productCardInfo({
    size,
    hasPadding,
    productNameLineClamp,
    soldOut,
  });

  return (
    <div className={info.base()}>
      <div className={info.header()}>
        <div className={info.requiredInfo()}>
          {brandName && (
            <div className="flex items-center">
              <EmoTextButton
                size="small"
                priority="primary"
                actionIcon={onClickBrandName && 'chevronRight'}
                onClick={onClickBrandName}
              >
                {brandName}
              </EmoTextButton>
            </div>
          )}
          <p className={info.productName()}>{productName}</p>
        </div>
        <div className={info.priceInfo()}>
          {discount ? <p className={info.discount()}>{discount}%</p> : null}
          <p>{commaizeNumber(price)}</p>
        </div>
      </div>
      <div className={info.metaDataGroup()}>
        <BadgeGroup badges={badges} soldOut={soldOut} />
        <AmountMetaData likeAmount={likeAmount} reviewAmount={reviewAmount} size={size} />
      </div>
    </div>
  );
};

const BadgeGroup = ({ badges, soldOut }: { badges?: Badge[]; soldOut?: boolean }) => {
  const info = productCardInfo();

  return useMemo(() => {
    if (soldOut) {
      return (
        <div className={info.metaData()}>
          <Badge size="small" priority="tertiary">
            임시품절
          </Badge>
        </div>
      );
    }

    if (!badges || badges.length === 0) {
      return null;
    }

    return (
      <div className={info.metaData()}>
        {badges.map(({ name }) => (
          <Badge key={name} size="small" priority="secondary">
            {name}
          </Badge>
        ))}
      </div>
    );
  }, [badges, soldOut]);
};

const AmountMetaData = ({ likeAmount, reviewAmount, size }: Partial<MetaData> & ProductCardInfoVariants) => {
  const info = productCardInfo({
    size,
  });

  return useMemo(() => {
    if (!likeAmount || !reviewAmount) {
      return null;
    }
    return (
      <div className={info.metaData()}>
        <div className="flex items-center gap-2">
          <Icon type="heart" color="tertiary" size={12} />
          <span className={info.amountText()}>{commaizeNumber(likeAmount)}</span>
        </div>
        <div className="flex items-center gap-2">
          <Icon type="star" color="tertiary" size={12} />
          <span className={info.amountText()}>{commaizeNumber(reviewAmount)}</span>
        </div>
      </div>
    );
  }, [info, likeAmount, reviewAmount]);
};

export default memo(ProductCardInfo);
