import * as v from 'valibot';

export const SocialLoginResponse = v.object({
  message: v.string(),
  accessToken: v.optional(v.string()),
  socialType: v.optional(v.string()),
  socialEmail: v.string(),
  socialNo: v.string(),
  userName: v.optional(v.string()),
  birthDate: v.optional(v.string()),
  gender: v.optional(v.union([v.literal('M'), v.literal('F')])),
  ageRange: v.optional(v.number()),
  userId: v.optional(v.string()),
});

export type SocialLoginResponse = v.InferInput<typeof SocialLoginResponse>;
