import { UseInViewOptions, useInView } from '@29cm/hooks-dom';
import { PropsWithChildren, useEffect, useRef } from 'react';

interface Props
  extends Pick<UseInViewOptions, 'root' | 'rootMargin' | 'rootMargin' | 'threshold' | 'once' | 'initial'> {
  onView?: () => void;
}

export const Intersection = ({ children, onView, ...options }: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isInView = useInView(ref, options);

  useEffect(() => {
    if (!isInView) {
      return;
    }

    onView?.();
    //`onView`의 변경에 따라 useEffect를 다시 실행할 필요가 없으므로, 의존성 배열에는 포함하지 않습니다.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  return <div ref={ref}>{children}</div>;
};
