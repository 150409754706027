import { emoAlertDialog } from '@29cm/contexts-common-ruler';
import { navigateToLoginWithRedirectUrl } from './navigateToLoginWithRedirectUrl';

export const showLoginDialog = (message = '') => {
  emoAlertDialog.show({
    id: 'login-dialog',
    title: '로그인이 필요해요.',
    description: message,
    confirmText: '로그인 하기',
    cancelText: '취소',
    onConfirm: navigateToLoginWithRedirectUrl,
    // NOTE: virtual scroll 컴포넌트에서 포지션 문제가 발생하므로, loose한 preventScroll 옵션을 사용합니다.
    preventScroll: 'loose',
  });
};
