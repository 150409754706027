import * as v from 'valibot';
import { UserGrade } from './UserGrade';

export const User = v.object({
  id: v.string(),
  no: v.string(),
  creatorNo: v.optional(v.string()),
  name: v.string(),
  email: v.string(),
  grade: v.optional(UserGrade),
  gradeName: v.string(),
  gender: v.optional(v.union([v.literal('M'), v.literal('F')])),
  accessToken: v.string(),
  refreshToken: v.string(),
});

export type User = v.InferInput<typeof User>;
