'use client';

import { Banner } from '@29cm/contexts-banners-services';
import { bannerCarouselImagePriorityMaxIndex } from '@29cm/contexts-home/constants';
import { Carousel, CarouselRef } from '@29cm/contexts-home/views';
import { useCallback, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { BannerCarouselItem } from './BannerCarouselItem';
import { BannerCarouselNavigator } from './BannerCarouselNavigator';
import { BannerCarouselProgress } from './BannerCarouselProgress';

const horizontalPadding = {
  sm: '20px',
};

interface Props {
  className?: string;
  banners: Banner[];
}

export const BannerCarousel = ({ className, banners }: Props) => {
  const cardCarouselRef = useRef<CarouselRef>(null);
  const [focusedIndex, setFocusedIndex] = useState(0);

  const bannerList = banners.map((item) => ({ id: item.bannerId, item }));

  const renderItem = useCallback((item: (typeof bannerList)[number], index: number) => {
    return (
      <BannerCarouselItem
        item={item.item}
        priority={bannerCarouselImagePriorityMaxIndex >= index}
        position={index + 1}
      />
    );
  }, []);

  const handleFocusedIndexChange = useCallback((focusedIndex: number) => {
    setFocusedIndex(focusedIndex);
  }, []);

  if (bannerList.length === 0) {
    return null;
  }

  return (
    <div className={twMerge('relative my-8', className)}>
      <Carousel
        ref={cardCarouselRef}
        items={bannerList}
        horizontalPadding={horizontalPadding}
        gap={6}
        renderItem={renderItem}
        snapStop="always"
        onFocusedIndexChange={handleFocusedIndexChange}
      />

      <BannerCarouselNavigator
        className="absolute left-[44px] top-1/2 -translate-y-1/2 max-[375px]:left-[40px]"
        direction="previous"
        disabled={focusedIndex === 0}
        onClick={() => {
          cardCarouselRef.current?.focusTo(focusedIndex - 1);
        }}
      />
      <BannerCarouselNavigator
        className="absolute right-[44px] top-1/2 -translate-y-1/2 max-[375px]:right-[40px]"
        direction="next"
        disabled={focusedIndex === bannerList.length - 1}
        onClick={() => {
          cardCarouselRef.current?.focusTo(focusedIndex + 1);
        }}
      />

      <BannerCarouselProgress
        className="absolute inset-x-[62px] bottom-[52px] max-[375px]:inset-x-[40px] max-[375px]:bottom-[19px]"
        current={focusedIndex + 1}
        max={bannerList.length}
      />
    </div>
  );
};
