'use client';

import { Intersection } from '@29cm/contexts-common-features';
import { Responsive } from '@29cm/contexts-common-views';
import { Divider } from '@29cm/contexts-home/views';
import { Button } from '@29cm/ruler';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  onView?: () => void;
}

export const ReadMoreSection = ({ className, onView }: Props) => {
  const [infiniteScroll, setInfiniteScroll] = useState(false);

  const handleButtonClick = () => {
    setInfiniteScroll(true);
  };

  return (
    <div className={twMerge('mb-30 flex flex-col items-center gap-30 pt-30 md:pt-0', className)}>
      <Responsive className="w-full" md={infiniteScroll ? null : <Divider direction="horizontal" />} />

      {infiniteScroll ? (
        <Intersection onView={onView} rootMargin="600px" />
      ) : (
        <Button
          className="h-[52px] w-[160px] min-w-[88px]"
          priority="secondary"
          size="large"
          type="button"
          actionIcon="chevronDown"
          round
          onClick={handleButtonClick}
        >
          더보기
        </Button>
      )}
    </div>
  );
};
