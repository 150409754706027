'use client';

import { createContext } from 'react';
import { GlobalCacheRegistry } from '../interfaces';

type PromiseMap<T extends (...args: unknown[]) => unknown = (...args: unknown[]) => unknown> = Map<
  string,
  Promise<ReturnType<T>>
>;

type GlobalCacheContextValue = {
  globalCacheRegistry: GlobalCacheRegistry;
  promiseMap: PromiseMap;
};

export const GlobalCacheContext = createContext<GlobalCacheContextValue | undefined>(undefined);
