import { isMediaMatches } from '@29cm/utils-medias';
import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string, initial = false): boolean => {
  const [matches, setMatches] = useState<boolean>(initial);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    const handleChange = () => {
      setMatches(isMediaMatches(query));
    };

    handleChange();

    if (matchMedia.addEventListener) {
      matchMedia.addEventListener('change', handleChange);
      return () => {
        matchMedia.removeEventListener('change', handleChange);
      };
    }

    // legacy browser 대응
    matchMedia.addListener(handleChange);
    return () => {
      matchMedia.removeListener(handleChange);
    };
  }, [query]);

  return matches;
};
