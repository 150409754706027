export const enum AfParameterKeys {
  /** 미디어 소스, 앱스플라이어 연동 파트너를 고유하게 식별 */
  PID = 'pid',
  /** 캠페인 */
  C = 'c',
  /** 광고세트 */
  AF_ADSET = 'af_adset',
  /** 광고 */
  AF_AD = 'af_ad',
  /** 리타겟팅 캠페인 여부 */
  IS_RETARGETING = 'is_retargeting',
  /** 클릭 어트리뷰션을 위한 룩백 윈도우 */
  AF_CLICK_LOOKBACK = 'af_click_lookback',
  /** 스마트 스크립트 커스텀 파라미터 */
  AF_SS_UI = 'af_ss_ui',
  /** [리디렉션]데스크톱 사용자를 사용자 지정 웹 페이지로 리디렉션할 수 있습니다. */
  AF_WEB_DP = 'af_web_dp',
  /** [리디렉션] iOS 및 안드로이드 앱을 실행하기 위한 폴백 방법으로 사용되는 URI 스키마입니다. 이는 유니버설 링크 또는 안드로이드 앱 링크 메소드가 실패하고 안드로이드 버전이 6.0 미만인 경우에 사용되도록 앱을 실행합니다. 여기에서 값을 설정하여 원링크 템플릿에서 URI 스키마 기본값을 재정의합니다(필요한 경우에만). */
  AF_DP = 'af_dp',
  /** [딥링킹] 딥링킹과 디퍼드 딥링킹에 사용되는 앱링크입니다. 딥링킹은 앱 스토어나 앱 홈페이지로 먼저 연결되지 않고 기존 앱 사용자를 리타게팅하여 앱 내의 특정 경험(예: 특정 페이지)으로 직접 보내는 것을 의미합니다. 이러한 사용자는 리타게팅 어트리뷰션을 사용하여 어트리뷰션됩니다. 디퍼드 딥링킹은 신규 사용자에게 기존 사용자와 유사한 딥링킹 경험을 제공한다는 의미입니다. 그러나 사용자 흐름은 먼저 신규 사용자가 앱 스토어로 보내지고 앱을 내려받아 실행한 후 특정 경험이나 페이지로 보내지는 것입니다. */
  DEEP_LINK_VALUE = 'deep_link_value',
}
