'use client';

import { useAnalytics } from '@29cm/contexts-analytics/hooks';
import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { Intersection } from '@29cm/contexts-common-features';
import { ResponsiveImage } from '@29cm/contexts-common-views';
import { Feed } from '@29cm/contexts-feeds-services';
import { memo, useMemo } from 'react';

interface Props {
  feed: Feed;
}

export const BannerFeedLayout = memo(({ feed }: Props) => {
  const { relatedFiles, title } = feed;

  const [thumbnail] = relatedFiles;

  const track = useAnalytics();

  const feedProperties = useMemo(
    () => ({
      banner_no: feed.id,
      banner_title: feed.title,
      link_type: feed.linkType,
      link_value: feed.linkValue,
      position: feed.orderingIndex % 2 === 0 ? 'left' : 'right',
    }),
    [feed],
  );

  // TODO: 이벤트명 한 파일에서 관리 (SSOT 준수)
  const handleView = () =>
    track('view_side_home_banner', feedProperties, {
      platforms: { firebase: true },
    });

  // TODO: 이벤트명 한 파일에서 관리 (SSOT 준수)
  const handleClick = () =>
    track('click_side_home_banner', feedProperties, {
      platforms: { firebase: true },
    });

  return (
    <Intersection onView={handleView}>
      <div
        className="relative bg-low"
        style={{
          aspectRatio: thumbnail ? `${thumbnail.width}/${thumbnail.height}` : undefined,
        }}
        onClick={handleClick}
      >
        <ResponsiveImage
          src={thumbnail?.uploadName ?? transparentFallbackImageSrc}
          alt={title}
          sizes={{ sm: '100vw', md: '40vw' }}
          fill
          priority
        />
      </div>
    </Intersection>
  );
});
