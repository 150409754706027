import { MasonryLayout, MasonryLayoutProps, Responsive } from '@29cm/contexts-common-views';
import { Feed, FeedType } from '@29cm/contexts-feeds-services';
import dynamic from 'next/dynamic';
import { ComponentProps, useCallback } from 'react';
import { BannerFeed } from '../BannerFeed';
import { ContentsFeed } from '../ContentsFeed';
import { FeedList } from '../FeedList';
import { ProductCollectionFeed } from '../ProductCollectionFeed';

const feedByType = {
  [FeedType.BANNER]: BannerFeed,
  [FeedType.CONTENTS]: ContentsFeed,
  [FeedType.PRODUCT]: ProductCollectionFeed,
} as const;

const getFeedByType = (type: (typeof FeedType)[keyof typeof FeedType]) => feedByType[type];

interface Props {
  className?: string;
  mobileFeeds: Feed[];
  desktopFeeds: Feed[];
}

export const FeedSection = ({ className, mobileFeeds, desktopFeeds }: Props) => {
  const renderItem = useCallback(({ data }: { data: Feed }) => {
    const FeedComponent = getFeedByType(data.type);

    return <FeedComponent item={data} />;
  }, []);

  return (
    <Responsive
      className={className}
      sm={<FeedList items={mobileFeeds} renderItem={renderItem} />}
      md={<Masonry columnCount={2} items={desktopFeeds} renderItem={renderItem} />}
    />
  );
};

const Masonry = dynamic<ComponentProps<(props: MasonryLayoutProps<Feed>) => ReturnType<typeof MasonryLayout>>>(
  () => import('@29cm/contexts-common-views').then((module) => module.MasonryLayout),
  {
    ssr: false,
  },
);
