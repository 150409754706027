import { isDevelopment } from '@29cm/utils-node';
import * as v from 'valibot';
import { User } from '../interfaces';

export const validateUser = (user: unknown): User | undefined => {
  const validation = v.safeParse(User, user).issues?.map((i) => i.message) ?? [];

  if (validation.length > 0) {
    const errorMessage = `쿠키 파싱 값과 스키마가 일치하지 않습니다. ${validation.join('\n')}`;
    if (isDevelopment()) {
      throw Error(errorMessage);
    } else {
      console.error(errorMessage);
      return;
    }
  }

  return user as User;
};
