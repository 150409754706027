import { useAuthenticationContext } from '@29cm/contexts-auth/features';
import { showLoginDialog } from '@29cm/contexts-auth/utils';
import { ProductCard, ProductCardProps } from '@29cm/contexts-common-ruler';
import { useProductHeart } from '@29cm/contexts-hearts-features';
import { memo } from 'react';

export type ProductCardWithHeartProps = ProductCardWithLoginProps;

export const ProductCardWithHeart = memo((props: ProductCardWithHeartProps) => {
  const { isLogged } = useAuthenticationContext();

  if (isLogged) {
    return <ProductCardWithLogin {...props} />;
  }

  return <ProductCard {...props} onHeart={false} onHeartClick={showLoginDialog} />;
});

interface ProductCardWithLoginProps extends Omit<ProductCardProps, 'onHeart' | 'onHeartClick'> {
  productId: number;
}

const ProductCardWithLogin = memo(({ productId, likeAmount, ...rest }: ProductCardWithLoginProps) => {
  const { isHeartOn, heartCount, toggleHeart } = useProductHeart(productId, likeAmount ?? 0);

  return <ProductCard {...rest} onHeart={isHeartOn} likeAmount={heartCount} onHeartClick={toggleHeart} />;
});
