const MILLION = 1000000 as const;
const KILO = 1000 as const;

export const unitizeNumber = (value: number): string => {
  if (value >= MILLION) {
    return `${Math.floor(value / MILLION)}M`;
  }

  if (value >= KILO) {
    return `${Math.floor(value / KILO)}K`;
  }

  return `${value}`;
};
