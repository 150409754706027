import { isServer } from '@29cm/utils-contexts';
import { add } from '@29cm/utils-dates';
import { RemoveCookieOptions, SetCookieOptions } from '../interfaces';
import { CookieStore } from '../interfaces/CookieStore';
import { createCookieGetter } from '../utils';

/**
 * cookieStore 생성 서비스 함수 입니다. cookieStore는 cookie 값을 설정, 조회, 구독/해지 구독을 위한 메서드를 제공합니다.
 */
export const createCookieStore = (): CookieStore => {
  const subscriberMap = new Map<string, Set<() => void>>();

  /**
   * document.cookie 에 접근하여 인자로 받은 key 에 해당하는 value 를 반환 합니다.
   */
  const retrieve = (key: string): string => {
    if (isServer()) {
      throw Error('서버에서는 document.cookie를 읽어올 수 없습니다.');
    }

    const cookieGetter = createCookieGetter(document.cookie);
    return cookieGetter(key);
  };

  /**
   * document.cookie 에 접근하여 인자로 받은 key 와 value 를 options 에 맞게 세팅 합니다.
   * cookie 는 모든 네트워크 요청에 포함되므로, 쿠키의 사용이 반드시 필요한 데이터의 경우에만 사용해 주세요.
   *
   * @param key 쿠키 key
   * @param value 쿠키 값
   * @param options 쿠키 설정에 대한 추가 옵션
   * @param options.end 쿠키의 만료 날짜를 설정합니다.
   * @param options.domain 쿠키가 유효한 도메인을 설정합니다. 이 옵션이 지정되지 않으면 호출 스크립트의 도메인에 대해 유효합니다.
   * @param options.path 쿠키가 유효한 도메인 내의 경로를 설정합니다. 이 옵션이 지정되지 않으면 호출 스크립트의 경로에 대해 유효합니다.
   * @param options.secure 쿠키가 HTTPS 연결에서만 전송되도록 할지를 결정합니다. true이면 secure 속성이 설정되고, false이거나 생략되면 설정되지 않습니다.
   */
  const set = (key: string, value: string | number | boolean, options: SetCookieOptions = {}): boolean => {
    if (isServer()) {
      throw Error('서버에서는 document.cookie를 설정할 수 없습니다.');
    }

    if (!key || /^(?:expires|max-age|path|domain|secure)$/i.test(key)) {
      return false;
    }

    const { end, domain, path, secure } = options;

    const expires = end !== undefined ? `; expires=${end.toUTCString()}` : 0;

    document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)}${expires}${
      domain ? `; domain=${domain}` : ''
    }${path ? `; path=${path}` : ''}${secure ? '; secure' : ''}`;

    notify(key);

    return true;
  };

  const remove = (key: string, options: RemoveCookieOptions = {}) => {
    if (isServer()) {
      throw Error('서버에서는 document.cookie를 설정할 수 없습니다.');
    }

    // expire date 를 과거로 설정하여 cookie 가 삭제되도록 합니다.
    set(key, '', { end: add(new Date(), { days: -1 }), ...options });
  };

  const notify = (key: string) => {
    const subscribers = subscriberMap.get(key);

    subscribers?.forEach((subscriber) => subscriber());
  };

  /**
   * 특정 쿠키 key의 변경 사항을 구독하는 함수입니다.
   * 쿠키가 변경될 때마다 호출될 콜백을 등록합니다.
   */
  const subscribe = (key: string, subscriber: () => void): (() => void) => {
    if (!subscriberMap.has(key)) {
      subscriberMap.set(key, new Set());
    }

    const subscribers = subscriberMap.get(key);

    subscribers?.add(subscriber);

    return () => unsubscribe(key, subscriber);
  };

  const unsubscribe = (key: string, subscriber: () => void) => {
    const subscribers = subscriberMap.get(key);

    subscribers?.delete(subscriber);

    if (subscribers?.size === 0) {
      subscriberMap.delete(key);
    }
  };

  return {
    set,
    remove,
    retrieve,
    subscribe,
  };
};
