import * as v from 'valibot';

enum Grade {
  VIP = 10,
  RED = 20,
  ORANGE = 30,
  YELLOW = 40,
  GREEN = 50,
  FAMILY = 98,
  STAFF = 99,
}

export const UserGrade = v.enum(Grade);

export type UserGrade = v.InferInput<typeof UserGrade>;
