'use client';

import { Masonry, MasonryProps, RenderComponentProps } from 'masonic';
import { ReactNode, memo } from 'react';

export type MasonryLayoutRenderItemProps<T> = Pick<RenderComponentProps<T>, 'index' | 'data'>;

export type MasonryLayoutRenderItem<T> = ({ index, data }: MasonryLayoutRenderItemProps<T>) => ReactNode;
export interface MasonryLayoutProps<T extends { id: string | number } = { id: string | number }>
  extends Pick<MasonryProps<T>, 'columnGutter' | 'columnCount' | 'maxColumnCount' | 'overscanBy'> {
  items: T[];
  renderItem: MasonryLayoutRenderItem<T>;
}

const MasonryLayoutComponent = ({
  items,
  columnGutter,
  columnCount,
  maxColumnCount,
  overscanBy = 2,
  renderItem,
}: MasonryLayoutProps) => {
  return (
    <Masonry
      columnGutter={columnGutter}
      columnCount={columnCount}
      maxColumnCount={maxColumnCount}
      overscanBy={overscanBy}
      items={items}
      render={renderItem}
      itemKey={(data) => data.id}
    />
  );
};

export const MasonryLayout = memo(MasonryLayoutComponent) as <T extends { id: string | number }>(
  props: MasonryLayoutProps<T>,
) => ReturnType<typeof MasonryLayoutComponent>;
