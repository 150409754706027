import { appEnv } from './appEnv';

export const authAppPath =
  appEnv === 'production'
    ? 'https://auth.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-auth.29cm.co.kr'
      : 'https://dev-auth.29cm.co.kr';
export const homeAppPath =
  appEnv === 'production'
    ? 'https://home.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-home.29cm.co.kr'
      : 'https://dev-home.29cm.co.kr';
export const shopAppPath =
  appEnv === 'production'
    ? 'https://shop.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-shop.29cm.co.kr'
      : 'https://dev-shop.29cm.co.kr';
export const productAppPath =
  appEnv === 'production'
    ? 'https://product.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-product.29cm.co.kr'
      : 'https://dev-product.29cm.co.kr';
export const orderAppPath =
  appEnv === 'production'
    ? 'https://order.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-order.29cm.co.kr'
      : 'https://dev-order.29cm.co.kr';
export const searchAppPath =
  appEnv === 'production'
    ? 'https://shop.29cm.co.kr/search'
    : appEnv === 'qa'
      ? 'https://qa-shop.29cm.co.kr/search'
      : 'https://dev-shop.29cm.co.kr/search';
export const postAppPath =
  appEnv === 'production'
    ? 'https://content.29cm.co.kr/post'
    : appEnv === 'qa'
      ? 'https://qa-content.29cm.co.kr/post'
      : 'https://dev-content.29cm.co.kr/post';
export const inboxAppPath =
  appEnv === 'production'
    ? 'https://inbox.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-inbox.29cm.co.kr'
      : 'https://dev-inbox.29cm.co.kr';
export const customerAppPath =
  appEnv === 'production'
    ? 'https://customer.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-customer.29cm.co.kr'
      : 'https://dev-customer.29cm.co.kr';
export const contentAppPath =
  appEnv === 'production'
    ? 'https://content.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-content.29cm.co.kr'
      : 'https://dev-content.29cm.co.kr';
export const eventAppPath =
  appEnv === 'production'
    ? 'https://event.29cm.co.kr'
    : appEnv === 'qa'
      ? 'https://qa-event.29cm.co.kr'
      : 'https://dev-event.29cm.co.kr';
