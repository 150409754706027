import { PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface ResponsiveProps {
  className?: string;
  sm?: ReactNode;
  md?: ReactNode;
}

export const Responsive = ({ className, sm, md }: PropsWithChildren<ResponsiveProps>) => {
  return (
    <>
      {sm && <div className={twMerge(`block md:hidden`, className)}>{sm}</div>}
      {md && <div className={twMerge(`hidden md:block`, className)}>{md}</div>}
    </>
  );
};
