import { useUserContext } from '@29cm/contexts-users/features';
import { useCallback, useEffect } from 'react';
import { Analytics, AnalyticsPlatforms } from '../interfaces';

export const useInitAnalytics = (services: Analytics[]) => {
  const { user } = useUserContext();

  useEffect(() => {
    if (user?.no === undefined) {
      return;
    }

    services.forEach((service) => service.setUser(user.no));
  }, [services, user?.no]);

  useEffect(() => {
    services.forEach((service) => service.init());
  }, [services]);

  return useCallback(
    (name: string, properties?: Record<PropertyKey, unknown>, platforms?: AnalyticsPlatforms) =>
      services.forEach((service) => {
        const isActive = platforms?.[service.platform];

        if (isActive) {
          service.track(name, properties);
        }
      }),
    [services],
  );
};
