import { useCallback, useRef } from 'react';

const serialize = (name: string, properties: Record<PropertyKey, unknown>): string =>
  `${name}/${JSON.stringify(properties)}`;

export const useTrackState = () => {
  const trackedEventMap = useRef<Record<string, boolean>>({});

  const isTracked = useCallback((name: string, properties: Record<PropertyKey, unknown> = {}) => {
    return trackedEventMap.current[serialize(name, properties)] === true;
  }, []);

  const setTracked = useCallback((name: string, properties: Record<PropertyKey, unknown> = {}) => {
    trackedEventMap.current[serialize(name, properties)] = true;
  }, []);

  return {
    isTracked,
    setTracked,
  };
};
