import { appScheme, workspaceEnv } from '@29cm/contexts-common-constants';
import { urlJoin } from '@29cm/utils-url';

/**
 * 현재 URL의 pathname과 workspaceEnv에 기반하여 앱 링크를 생성합니다.
 * - `workspaceEnv`가 'product'일 경우, 경로에서 '/catalog/' 부분을 제거하고 앱 링크를 생성합니다.
 * - `workspaceEnv`가 'content'이면서 `pathname`이 '/post'로 시작하는 경우, '/post'를 제외한 경로로 앱 링크를 생성합니다.
 * - 그 외 `workspaceEnv`는 `href` 값을 사용하여 기본 앱 링크를 생성합니다.
 */
export const getCurrentLinkBasedAppLink = () => {
  const pathname = document.location.pathname;
  const href = document.location.href;

  const appPathname = urlJoin(workspaceEnv, pathname);

  if (workspaceEnv === 'product') {
    // NOTE: 신규 상품 페이지에서 기존 딥링크 체계로 사용하기 위해서 수정합니다.
    // app29cm://product/catalog/12345 -> app29cm://product/12345
    // return `${appScheme}${appPathname.replace('/catalog/', '/')}`;
    return `${appScheme}${appPathname}`.replace(/\/\/product\/catalog\//, '//product/');
  }

  if (workspaceEnv === 'content' && pathname.startsWith('/post')) {
    // NOTE: 신규 포스트 페이지에서 기존 딥링크 체계로 사용하기 위해서 수정합니다.
    // app29cm://content/post/12345 -> app29cm://post/12345
    return `${appScheme}${appPathname}`.replace(/\/\/content\/post\//, '//post/');
  }

  if (workspaceEnv === 'home') {
    return `${appScheme}/${appPathname}`;
  }

  return `${appScheme}web/${href}`;
};
