'use client';

import { createContext } from 'react';
import { CookieStore } from '../../interfaces';

interface CookieContextType {
  store: CookieStore;
  initialCookie: string;
}

export const CookieContext = createContext<CookieContextType | undefined>(undefined);
